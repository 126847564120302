import React, {useEffect, useState} from "react";
import {Header, ScrollLoadingInspiration, ContainerUpCase, Search, Footer, Loading} from "../module/group"
import {INSPIRATION} from '../functions/functions'
import {sizeType} from "../functions/mainFunction"
import "../scss/min/design.inspiration.scss"

function Inspiration(){  
    const [data, setData] = useState([])

    const getSeachFild = r => {
        console.log(r)
    }

    async function getData(){
        const request = await new INSPIRATION().getAllInspiration()
        setData(request)
    }

    useEffect(() => {
        sizeType()
        getData()
    },[])

    return (
        <>
            <Header icon={1} color={"#5C3A94"}/>

            <main id="designInspiration_main">
                <ContainerUpCase textColor={"#77B72A"} lineColor={"#77B72A"} type={2} writer={"Gilberto Strunck"} text={"“Bom Design vicia, porque dá bons resultados”"} />                
                
                <div className="container-component">
                    <Search getSeachFild={getSeachFild}/>
                    {data[0] ? (<ScrollLoadingInspiration limit="12" itens={data}/>) : (<Loading/>)}
                    
                </div>
            </main>

            <Footer/>
        </>
    )
}

export default Inspiration