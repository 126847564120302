import React, {useEffect, useState} from "react";
import {Header, ShowPortifolio, ContainerUpCase, Search} from "../module/group"
import {sizeType} from "../functions/mainFunction"
import "../scss/min/portifolio.scss"

function Portifolio(){

    const getSeachFild = r => {
        console.log(r)
    }

    useEffect(() => {
        sizeType()
    },[])

    return (
        <>
            <Header icon={1} color={"#4F3CC7"}/>

            <main id="portifolio_main">
                <ContainerUpCase textColor={"#810296"} lineColor={"#810296"} text={"Produtos com design único, seu negocio no topo! "} />                
                
                <div className="container-component">
                    <Search getSeachFild={getSeachFild}/>
                    <ShowPortifolio/>
                </div>
            </main>
        </>
    )
}

export default Portifolio