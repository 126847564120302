import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import {Header, ShowBallImages} from "../module/group"
import {sizeType} from "../functions/mainFunction"
import "../scss/min/branding.scss"

import iconBrandingCastle from "../images/icon/iconBrandingCastle.png"
import iconBrandingCreate from "../images/icon/iconBrandingCreate.png"
import iconBrandingModify from "../images/icon/iconBrandingModify.png"

function Branding(){

    useEffect(() => {
        sizeType()
    })

    return (
        <>
            <Header icon={1} color={"#1A1A1A"}/>

            <main id="main_branding">
                <div className="container-UpCase">
                    <span className="text-UpCase">Qual opção descreve melhor suas necessidades de <i>branding</i> ?</span>
                    <ShowBallImages quant={2} formate={[{x: 35, y: 62}, {x: 146, y: 105}]}/>
                </div>

                <div className="container-options">
                    <span className="text-WarningText">*Selecione alguma opção para continuar*</span>

                    <div className="content-options">
                        <Link to="/create/new/Branding/0">
                            <div className="box-options">
                                <img src={iconBrandingCreate} alt="" />
                                <span className="text-titleOptions">Quero criar uma nova marca</span>
                                <span className="text-subTitleOptions">do zero</span>
                            </div>
                        </Link>
                        <Link to="/create/new/Branding/1">
                            <div className="box-options">
                                <img src={iconBrandingCastle} alt="" />
                                <span className="text-titleOptions">Tenho uma empresa</span>
                                <span className="text-subTitleOptions">e quero criar uma marca para ela</span>
                            </div>
                        </Link>
                        <Link to="/modify/Branding/2">
                            <div className="box-options">
                                <img src={iconBrandingModify} alt="" />
                                <span className="text-titleOptions">Eu tenho uma marca existente</span>
                                <span className="text-subTitleOptions">que eu gostaria de <i>remodelar</i> ou expandir</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Branding