import {useState, useEffect} from 'react'
import { useNavigate, useParams } from "react-router-dom";
import {Header, ContainerUpCase, Loading} from "../module/group"
import {BrandingResumeIA} from '../module/components/Create.Quis.Modules'
import {USER, DESIGNPAGE, BUDGET} from '../functions/functions'
import {IMAGE} from '../functions/module/processing.data'
import {sizeType} from "../functions/mainFunction"

import iconBrandingCastle from "../images/icon/iconBrandingCastle.png"
import iconBrandingCreate from "../images/icon/iconBrandingCreate.png"
import iconBrandingModify from "../images/icon/iconBrandingModify.png"
import "../scss/min/budget.view.scss"

function InformationBox({label, value}){
    return (
        <div className="container-information">
            <span className='text-name-column'>{label}</span>
            <span className='text-vall-column'>{value}</span>
        </div>
    )
}

function BoxContent({idBox, value, isTag}){
    return (
        <div className="box-content">
            <div className='line' id={`${idBox}-line`} />
            
            {!isTag ? (<p className='paragraph'>{value}</p>) : (
                <div className="container-tags">
                    {value[0] ? value.map((vall) => (<span className='tag' key={`${vall}-key-tag`}>{vall}</span>)) : ''}
                </div>
            )}
        </div>
    )
}

function GetImageRequest({typeRequest}){
    function GetImage(){
        switch(typeRequest){
            case 1: 
                return <img src={iconBrandingCreate} alt="create new" />
            break;
            case 2: 
                return <img src={iconBrandingModify} alt="modify logo" />
            break;
            default:
                return <img src={iconBrandingCastle} alt="create new logo" />
        }
    }

    return (
        <GetImage/>
    )
}

export default function BudgetView(){
    const navigate = useNavigate();
    const {codeID} = useParams()
    const [budget, setBudget] = useState([])
    const [user, setUser] = useState()
    const colorStatus = ["#E4C312", "#3e75b4", "#77B72A", "#B20C0C"]
    const titleStatus = ["Em analize", 'Requisição de orçamento', "Criando"]
    const textStatus = ['Em analize', "Criando", "Concluído", "Finalizado"]

    async function construct(){
        if(codeID){
            const request = await new BUDGET().getEspecificBudgetOfUser(codeID)
            if(request[0]){
                setBudget(request[1][0])
                setUser(request[2])
            }
        }else{
            navigate('/myAccount')
        }
    }

    function downloadImages(){
        return new IMAGE().download(budget.data.image, 'budget/send', true, user.data)
    }

    async function request_project(){
        if(user.data.typeUser === 1){
            const request = await new DESIGNPAGE().addInDesignerPortifolio(budget.id)
            if(request){
                alert('Projeto adicionado ao seu protifólio, bom trabalho!');
            }else{
                alert('Ei! tivemos um erro, tente novamente mais tarde.');
            }
        }
    }

    useEffect(() => {
        sizeType()
        new USER().isLogWithResponse(res => !res ? navigate('/login') : '')
        construct()
    },[])

    return (
        <>
            <Header icon={2} color={"#262172"}/>
            <main className='BudgetView_main'>
            {budget.data ? (
                <>
                    <ContainerUpCase type={3} link={user && user.data.typeUser !== 0 ? '/designer/page' : '/account'} fontSize={"2.3em"} textColor={"#EC6707"} lineColor={"#EC6707"} text={"Visualização"} />                
                    <div className="container_basicInformation">
                        <GetImageRequest typeRequest={budget.data.typeBanding}/>
                        <div className="box_information">
                            <span className='title-basicInformation'>{titleStatus[budget.data.status]}</span>
                            <span className='sub-title-basicInfomation'>status <i style={{color: colorStatus[budget.data.status]}}>{textStatus[budget.data.status]}</i></span>
                        </div>
                    </div>
                    <div className='line' id='ia-line' />

                    <BrandingResumeIA key={`resume-budget-view-${budget.data.nameProject}`} resume={budget.data.resume_ia}/>

                    <div className='line'/>

                    <div className="container-information-project">

                        <InformationBox label={'Nome do cliente: '} value={user.data.name}/>
                        {/* esconder email e telefone */}
                        {budget.data.designSelect !== 'false' ? (
                            <>
                                <InformationBox label={'Email: '} value={user.data.email}/>
                                <InformationBox label={'Telefone: '} value={user.data.tell}/>
                            </>
                        ): '' }
                        <InformationBox label={'Site: '} value={budget.data.website}/>
                        <InformationBox label={'Nome do projeto: '} value={budget.data.nameProject}/>
                        <InformationBox label={'Cor: '} value={budget.data.color}/>

                        <div className="container-social-midia">
                            <button className='button-social-midia'><img src="" alt="" /></button>
                        </div>

                        <BoxContent idBox={'description'} isTag={false} value={budget.data.descriptionInterprise} key={'description-tag'}/> 

                        {budget.data.slogan ? (<BoxContent idBox={'slogan'} isTag={false} value={budget.data.slogan} key={'slogan-tag'}/> ) : ""}
            
                        <BoxContent idBox={'public'} isTag={true} value={budget.data.targetAudience} key={'public-tag'}/> 

                        <BoxContent idBox={'elements'} isTag={true} value={budget.data.notIncludeElement} key={'elements-tag'}/> 

                        {/* <BoxContent idBox={'enterprise'} isTag={true} value={['APPLE']} key={'enterprise-tag'}/>  */}

                        <div className='line' />

                        {budget.data.image ? (
                            <button onClick={(e) => downloadImages()} className='button-download-images'>
                                Imagens de referência
                            </button>
                        ):""}

                        <div className='line' />

                        <div className="container-budget">
                            <span className="text-titlePage" style={{color: '#77B72A'}}>Criação</span>
                            <hr className="hr-titlePage" style={{backgroundColor: '#77B72A'}} />
                        
                            <button className='button-budget' id="style-donwload">visualizar orçamento</button>
                            {user.data.typeUser !== 0 ? (
                                <>
                                    {budget.data.designSelect === 'false' ? (
                                        <button onClick={(e) => request_project()} className='button-budget' id="style-get-me">Requisitar Projeto</button>
                                    ): ''}
                                    <button className='button-budget' id="style-upload">enviar um orçamento</button>
                                    <span className='text-cancel-budget'>Calcelar envio orçamento</span>
                                </>
                            ):""}
                        </div>

                    </div>
                </>
            ):<Loading/>}

            </main>

        </>
    )
}
