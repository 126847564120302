import React, {Component} from "react";
import { Link } from "react-router-dom";
import {USER} from "../../functions/functions"
import "../../scss/module/Menu.scss"

import instagran_icon from '../../images/icon/instagramIcon.png'
import linkedin_icon from '../../images/icon/linkedIn_icon.jpg'
import pinterest_icon from '../../images/icon/pinterest_icon.jpg'
import x_icon from '../../images/icon/x_icon.jpg'

class Menu extends Component {
    constructor(props){
        super(props)
        this.state = {
            open: false,
            display: "none",
            user: false
        }
    }

    async componentDidMount(){
        this.setState({open: this.props.open, display: this.props.open ? "flex" : "none"})
        const user = await new USER().getDataUser()
        this.setState({user: user})

    }

    componentDidUpdate(prevState, prevProps){
        const openMenu = () => {
            this.setState({open: this.props.open, display: "flex"})
        }

        const closeMenu = () => {
            this.setState({open: this.props.open})
            setTimeout(() => {
                this.setState({display: "none"})
            },500)
        }
        
        if(prevProps.open !== this.props.open){
            if(this.props.open){
                openMenu()
            }else{
                closeMenu()
            }
        }
    }

    setLocationAccount(){
        const user = this.state.user
        if(user.user){
            switch(user.data.data.typeUser){
                case 0:
                    return '/account'
                case 1: 
                    return '/designer/page'
                case 2: 
                    return '/admin/page'
                default:
                    return '/login'
            }
        }else{
            return '/login'
        }
    }
    setTextAccount(){
        const user = this.state.user
        if(user.user){
            switch(user.data.data.typeUser){
                case 0:
                    return 'Minha Conta'
                case 1: 
                    return 'Designer Page'
                case 2: 
                    return 'Admin'
                default:
                    return 'Login'
            }
        }else{
            return 'Login'
        }
    }

    sendCloseMenu(){
        this.props.modifyMenu(false)
    }

    render(){
        return (
            <>
                <div className="container-bigPage" style={{display: this.state.display}}>
                    <div className={`container-menu ${this.state.open ? "menuOpen-Open" : "menuOpen-close"}`}> 
                        <div className="content-upMenu">
                            <button onClick={(e) => this.sendCloseMenu()} className="button-closeMenu"></button>
                            <Link to={this.setLocationAccount()}><button className="button-loginOrMyAccount">{this.setTextAccount()}</button></Link>
                        </div>

                        <nav className="container-navigatePage">
                            <Link to="/"><button className="button-page">Home</button></Link>
                            <Link to="/portifolio"><button className="button-page">Portifólio</button></Link>
                            <Link to="/inspiration"><button className="button-page">Inspiração</button></Link>
                            <Link to="/branding"><button className="button-page">Orçamento</button></Link>
                            <Link to="/branding"><button className="button-page">Crie sua marca</button></Link>
                        </nav>

                        <hr />

                        <div className="container-slogan">
                           <a className="text-slogan" rel="noreferrer" target="_blank" href="https://www.instagram.com/designdeponta_oficial/">Design de Ponta</a> <span className="text-slogan">  Da ponta ao Ponto </span>
                        </div>

                        <div className="container-othesInformation">
                            <div className="content-social">
                                <a rel="noreferrer" target="_blank" href="www.instagram.com/designdeponta_oficial"><img src={instagran_icon} alt="" className="box-social" /></a>
                                <a rel="noreferrer" target="_blank" href="/"><img src={linkedin_icon} alt="" className="box-social" /></a>
                                <a rel="noreferrer" target="_blank" href="https://br.pinterest.com/DesignDePontaOficial/"><img src={pinterest_icon} alt="" className="box-social" /></a>
                                <a rel="noreferrer" target="_blank" href="https://x.com/DesignPonta"><img src={x_icon} alt="" className="box-social" /></a>
                            </div>
                            <Link to="/"><span className="text-private">Termos de Privacidade | Privacidade</span></Link>
                        </div>

                    </div>
                    <div onClick={(e) => this.sendCloseMenu()} className={`container-closeMenu`}></div>
                </div>
            </>
        )
    }
}

export default Menu