import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail} from "firebase/auth";
import { getValue } from "firebase/remote-config";
import {firestore, remoteConfig} from './QUERY'
const auth = getAuth();
const stateOfDevelopment = getValue(remoteConfig, "developer");

export class logUser{
    async getAuthenticateUser(email, password){
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const emailVerify = user.emailVerified
                const tokenAccess = user.accessToken
                const localID = user.reloadUserInfo.localId // ponte da auth com o bd
            
                const validate = stateOfDevelopment ? true : emailVerify

                if(validate){
                    resolve({login: true, data: [localID, tokenAccess]})
                }else{
                    resolve({login: false, why: "email"})
                }
            })
            .catch((error) => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
                reject({login: false, why: "password"})
            });    
        });
    }

    async getDataUser(uid, _id){
        const dataUser = await getInformationUserByUid(uid, _id)
        return dataUser ? {user:true, data: dataUser} : {user: false, why: "notUser"}

        async function getInformationUserByUid(uid, _id){
            const user = (await new firestore().getWhere({bd: 'user', where: [_id ? 'documentID' : 'uid', uid]}))
            return user[0]
        }
    }

    async logOutUser(){
        return new Promise((resolve, reject) => {
            signOut(auth).then(() => {
                sessionStorage.removeItem('ahjsgba87sga87s')
                resolve({logout: true})
            }).catch((error) => {
                reject({logout: false, why: error.message})
            });
        })
    }

    async getUser(){
        return new Promise((resolve, reject) => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const uid = user.uid;
                    resolve(uid)
                } else {
                    resolve(false)
                }
            });
        })
    }

    async userIsDesigner(id){
        const uid = (id ? id : await this.getUser());
        if(uid){
            const getController = controllerCachAdmin('', false)
            if(getController){
                return getController
            }else{
                const dataUser = await this.getDataUser(uid)
                controllerCachAdmin(dataUser.data.data.typeUser === 1, true)
            }
        }else{
            return false
        }

        function controllerCachAdmin(admin, setData){
            if(setData){
                sessionStorage.setItem('yars67asgy7atgsay', admin)
                return sessionStorage.getItem('yars67asgy7atgsay')
            }else{
                return sessionStorage.getItem('yars67asgy7atgsay')
            }
        }
    }

    async userIsAdm(id){
        const uid = (id ? id : await this.getUser());
        if(uid){
            const getController = controllerCachAdmin('', false)
            if(getController){
                return getController
            }else{
                const dataUser = await this.getDataUser(uid)
                controllerCachAdmin(dataUser.data.data.typeUser === 2, true)
            }
        }else{
            return 'false'
        }

        function controllerCachAdmin(admin, setData){
            if(setData){
                sessionStorage.setItem('tyfas65af6ags7as', admin)
                return sessionStorage.getItem('tyfas65af6ags7as')
            }else{
                return sessionStorage.getItem('tyfas65af6ags7as')
            }
        }
    }
}

export class registreUser{
    async registreUserWithEmail(email, password, name, tellphone, typeUser){
        return new Promise((resolve, reject) => {
            const auth = getAuth();

            createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
              const user = userCredential.user;
              const localID = user.reloadUserInfo.localId // ponte da auth com o bd
            
              const registreInBd = await this.registreUserInBd(name, localID, email, tellphone, typeUser)
          
              if(!registreInBd){
                reject({registre: false, why: "#1721872"})
              }

              sendEmailVerification(user)
                .then(() => {
                   resolve({registre: true})
                }).catch(() => {
                    reject({registre: false, why: "#8176278"})
                });
            })
            .catch(() => {
                reject({registre: false, why: "#9087511"})
            });
        })
    }

    async registreUserInBd(name, uid, email, tellphone, typeUser){
        const createDataUser = {
            name: name,
            tell: tellphone,
            typeUser: typeUser,
            uid: uid,
            email: email,
            socialMedia: {
                instagram: '',
                facebook: '',
                linkeding: ''
            }
        }

        const added = await new firestore().insertData({bd: 'user', insert_data: createDataUser})
        return added ? true : false
    }
}

export class RedifinePassword{
    sendCode(email){
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            sendPasswordResetEmail(auth, email)
            .then(() => {
                resolve(true)
            })
            .catch(() => {
              reject(false)
            });
        })
    }
}