import React, { Component } from "react";
import { Link } from "react-router-dom";

class Scroll extends Component {
    constructor(props){
        super(props)
        this.state = {
            il: [],
            paginationRef: [],
            items: [],
            page: 0
        }
    }

    buildPagination(ilustration){
        ilustration = this.state.il[0] ? this.state.il : ilustration

        var pagination = [[]]
        var selectPagination = 0
        var countForAddPagination = 0
    
        for (let i = 0; i < ilustration.length; i++) {
            if(countForAddPagination <= this.props.limit){
              pagination[selectPagination].push(ilustration[i])
              countForAddPagination++
            }else{
              pagination.push([])
              selectPagination++
              countForAddPagination = 0
            }
        }
    
        this.setState({paginationRef: pagination, items: pagination[this.state.page]})
    } 

    fetchData = (page) => {
        if(this.state.paginationRef[page]){
          const newItems = this.state.paginationRef[page]

          this.setState({items: [...this.state.items, ...newItems]})
        }
    }

    onScroll = () => {
        const scrollTop = document.documentElement.scrollTop
        const scrollHeight = document.documentElement.scrollHeight
        const clientHeight = document.documentElement.clientHeight
    
        if (scrollTop + clientHeight + 403 >= scrollHeight) {
            if(this.state.page < (this.state.paginationRef.length - 1)){
            this.setState({page: (this.state.page + 1)})
          }
        }
    }

    componentDidMount(){
        this.setState({il: this.props.itens})
        this.buildPagination(this.props.itens)
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.items !== this.state.items){
            window.addEventListener('scroll', this.onScroll)
            return () => window.removeEventListener('scroll', this.onScroll)
        }

        if(prevState.page !== this.state.page){
            this.fetchData(this.state.page)
        }

        if(prevState.il !== prevProps.itens){
            this.setState({il: this.props.itens})
        }
    }

    render(){
        return (
            <>
                <div className='container-portifolio'>
                  { this.state.items ? this.state.items.map((il, position) => (
                      <Link to={`/show/portifolio/${il.data.nameProject.replace(/ /g, "+")}/${il.id}`} key={`portifolio-${il.id}-${position}`} >
                          <div className="content-project">
                              <img src={il.data.coverImage} alt="Images portifóio" />
                              <div className="box-backgroundInformation">
                                  <span className="text-nameProject">{il.name}</span>
                              </div>
                          </div>
                      </Link>
                  )): ""}
                </div>
            </>
        )
    }
}

export default Scroll