import {firestore} from './QUERY'
import {USER, BUDGET} from '../functions'

export class DESIGNPAGE{

    async requestAllBudgets(){
        const request_budgets = await new BUDGET().getAllBudgets()
        return request_budgets
    }
    
    async requestMyBudgets(){
        const request_DesignersBudgets = await new BUDGET().getBudgetOfDesigner()
        return request_DesignersBudgets
    }

    async addInDesignerPortifolio(uidProject){
        const user = await new USER().getDataUser()
        if(user.user && user.data.data.typeUser === 1){
            const update_budget = await new firestore().updateData({bd: 'budget', update: {'id': uidProject, 'data': {'designSelect': user.data.id}}, })
            return update_budget
        }else{
            return false
        }
    }
}