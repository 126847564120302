import React, {useState, useEffect} from 'react';
import {registreUser} from '../functions/functions'
import {Header} from "../module/group"
import { Link } from "react-router-dom";

import siteIcon from "../images/icon/siteIcon.svg"
import lockIcon from "../images/icon/LockIcon.svg"
import userIcon from "../images/icon/userIcon.svg"
import googleIcon from "../images/icon/googleIcon.png"
import appleIcon from "../images/icon/appleIcon.png"
import instagramIcon from "../images/icon/instagramIcon.png"

import "../scss/min/login.scss"

function Registre(){
    const [passwordField, setPasswordField] = useState('')
    const [optionForResgistre, setOptionForResgistre] = useState()
    const [checkPasswordField, setCheckPasswordField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [nameField, setNameField] = useState('')
    const [tellField, setTellField] = useState('')
    const [configPage, setConfigPage] = useState({protocol: [1,0,0]})
    const [warning, setWarning] = useState({display: "none", text: ""})
    const [sendOtherEmailTime, setSendOtherEmailTime] = useState("01:30")

    async function registre(e){
        e.preventDefault()
        if(!AllFieldsHaveValue()){
            return 
        }
        if(!samePasswords()){
            return
        }
        
        
        const userRegistre = await new registreUser().registreUserWithEmail(emailField, passwordField, nameField, tellField, optionForResgistre);

        if(userRegistre.registre){
            setConfigPage({protocol: [0,0,1]})
        }

        function AllFieldsHaveValue(){
            if(emailField === "" || passwordField === "" || checkPasswordField === "" || nameField === "" || tellField === "" || optionForResgistre === null){
                setWarning({display: "block", text: "Prencha todos os valores"})
                return false
            }else{
                return true
            }
        }

        function samePasswords(){
            if(passwordField !== checkPasswordField){
                setWarning({display: "block", text: "As senhas não coencidem, tente verificar a ortográfia."})
                return false
            }else{
                return true
            }
        }
    }

    function sendEmail(){
        if(timeHasBeenReset){
            new registreUser().sendEmail(emailField, Response => {
                if(Response.send){
                    setSendOtherEmailTime("05:00")
                    setWarning({display: "block", text: "Verifique seu e-mail, lembre de verificar o spam!"})
                }else{
                    setWarning({display: "block", text: "Ops! não conseguimos enviar um email alternativo, tente novamente mais tarde"})
                }
            })
        }

        function timeHasBeenReset(){
            const time = sendOtherEmailTime.split(":")
            if(parseInt(time[0]) === 0 && parseInt(time[1]) === 0){
                return true
            }else{
                return false
            }
        }
    }

    const countDow = () => {
        const time = sendOtherEmailTime.split(":")

        function count(time){
            var minute = parseInt(time[0])
            var second = parseInt(time[1])

            if(minute >= 1 && second >= 0){
                if(minute === 0 && second >= 1){
                    second--
                }else{
                    if(second === 0){
                        minute--
                        second = 59
                    }else{
                        second--
                    }
                }
            }else{
                if(minute === 0 && second === 0 ){
                    minute = 0
                    second = 0
                }else{
                    second--
                }
            }
            return `${(minute <= 9 ? `0${minute}` : minute)}:${second <= 9 ? `0${second}` : second}`
        }

        setSendOtherEmailTime(count(time))
    }

    function registreAuthenticate(form){
        // new registreUser().registreAutheticate(form, optionForResgistre, Respose => {
        //     if(Respose.login){
        //         navigate("/babyPainel")
        //     }else{
        //         if(Respose.why && Respose.why === "notSupported"){
        //             setWarning({display: "block", text: `Ah não! estamos trabalhando para concluir esta implementação.`})
        //         }else{
        //             setWarning({display: "block", text: `Ah não! não conseguimos nos conectar com os servidores de autenticação, tente novamente mais tarde.`})
        //         }
        //     }
        // })
    }

    function pageSelectWithHistory(){
        if(isRegistre()){
            setPage("already")
        }else{
            setPage("new")
        }

        function isRegistre(){
            // return (getCookie("registrePath") && getCookie("registrePath") === "mailVerify") ? true : false
        }

        function setPage(type){
            if(type === "already"){
                setConfigPage(prevState => {return {...prevState, protocol: [0,0,1]}})
            }else{
                setConfigPage(prevState => {return {...prevState, protocol: [1,0,0]}})
            }
        }
    }

    function setOptionForRegistre(option){
        setOptionForResgistre(option)
        setConfigPage({protocol: [0,1,0]})
    }

    useEffect(() => {
        setSendOtherEmailTime("01:30")
        pageSelectWithHistory()
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            countDow()
        }, 1000);
        return () => clearInterval(interval);
    }, [sendOtherEmailTime]);

    return (
        <>
            <Header icon={2} color={"#1A1A1A"}/>
            <main className='main-login'>

                {configPage.protocol[0] ? (
                    <>
                        <div className="container-protocol style-Choise">
                            <h1 className='text-ChoiceTittle'>Seleciona uma das opções abaixo para continuar</h1>

                            <div className="container-ChoiseOptions">
                                <div onClick={(e) => setOptionForRegistre(1)} className="box-Choise style-personal">
                                    <span className="text-boxChoise">Pessoal</span>
                                    <span className='text-subtittleChoise'>Para criar projetos e tirar meus projetos do papel</span>
                                </div>
                                <div onClick={(e) => setOptionForRegistre(2)} className="box-Choise style-institutional">
                                    <span className="text-boxChoise">Sou Design</span>
                                    <span className='text-subtittleChoise'>Criar e ajudar outros a tirar seus projetos do papel</span>
                                </div>
                            </div>

                        </div>
                        <Link to="/login"><button className='button-alterateTypeUser style-login'>Login</button></Link>
                    </>
                ) : configPage.protocol[1] ? (
                    <>
                        <div className="container-protocol">
                            <div className="container-authenticateLogin">
                                <div onClick={(e) => registreAuthenticate(0)} className="box-authenticator" id="login_google"><img src={googleIcon} alt="" /></div>
                                <div onClick={(e) => registreAuthenticate(1)} className="box-authenticator" id="login_apple"><img src={appleIcon} alt="" /></div>
                                <div onClick={(e) => registreAuthenticate(2)} className="box-authenticator" id="login_instagram"><img src={instagramIcon} alt="" /></div>
                            </div>

                            <div className="container-loginDefault container-registreDefault">
                                <div className="container-warning style-warning" style={{display: warning.display}}><span>{warning.text}</span></div>
                                <form onSubmit={registre}>
                                    <div className="box-interativeUser">
                                        <span className='text-inputField'>E-mail</span>
                                        <input type="email" id="emailField" className='input-FieldInterative' placeholder='E-mail' value={emailField} onChange={(e) => {setEmailField(e.target.value)}}/>
                                    </div>
                                    <div className="box-interativeUser">
                                        <span className='text-inputField'>Nome</span>
                                        <input type="text" id="emailField" className='input-FieldInterative' placeholder='Nome' value={nameField} onChange={(e) => {setNameField(e.target.value)}}/>
                                    </div>
                                    <div className="box-interativeUser">
                                        <span className='text-inputField'>Telefone</span>
                                        <input type="text" id="emailField" className='input-FieldInterative' placeholder='Telefone' value={tellField} onChange={(e) => {setTellField(e.target.value)}}/>
                                    </div>
                                    <hr />
                                    <div className="box-interativeUser">
                                        <span className='text-inputField'>Senha</span>
                                        <input type="password" minLength={5} autoComplete="on" id="passwordField" className='input-FieldInterative' placeholder='Senha' value={passwordField} onChange={(e) => {setPasswordField(e.target.value)}}/>
                                    </div>
                                    <div className="box-interativeUser">
                                        <span className='text-inputField'>Confirmar Senha</span>
                                        <input type="password" minLength={5} autoComplete="on" id="checkPasswordField" className='input-FieldInterative' placeholder='Senha' value={checkPasswordField} onChange={(e) => {setCheckPasswordField(e.target.value)}}/>
                                    </div>


                                    <div className="box-interativeUser">
                                        <button type="submit" className='button-formButton style-registre'>Cadastrar</button>
                                    </div>
                                </form>
                            </div>

                            <button onClick={(e) => setConfigPage({protocol: [1,0,0]})} className='button-alterateTypeUser style-login'> &#60;- Voltar</button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="container-protocol">
                            <div className="container-warningRegistre style-warning" style={{display: warning.display}}><span>{warning.text}</span></div>

                            <div className="container-emailVerify">
                                <div className="container-warningRegistre"><span><b>Enviamos um código</b> de confirmação para “<i>{emailField}</i>”, verifique o spam caso não o encontre</span></div>
                                <div className="container-content-image">
                                    <div className="content-images">
                                        <img src={siteIcon} alt="" className='image-Site'/>
                                        <img src={lockIcon} alt="" className='image-Lock'/>
                                        <img src={userIcon} alt="" className='image-User'/>
                                    </div>
                                    <div className="box-ball style-right"></div>
                                    <div className="box-ball style-left"></div>
                                </div>
                                <span onClick={(e) => sendEmail(e)} className='text-confirmCode'>Reenviar código ({sendOtherEmailTime})</span>                            
                            </div>

                            <Link to="/login"><button className='button-formButton'>Login</button></Link>
                        </div>
                    </>
                )}
            </main>
        </>
    )
}

export default Registre