/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {Header, LineAddSetImage, Loading} from "../../module/group"
import {ADMINPAGE, USER} from '../../functions/functions'
import {sizeType} from "../../functions/mainFunction"
import {useNavigate} from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from "../../images/icon/IconImage.svg"
import "../../scss/min/show.portifolio.scss"


function CreateLayout({layout, callbackImage}){
    const [boxLayout, setBoxLayout] = useState([0,0]) // index, subindex

    function callback(file){
        if(file){
            callbackImage(boxLayout, file)
        }
    }

    return (
        <>
            {layout.length !== 0 ? layout.map((image, index) => (
                <div className="box-Images">
                    <label htmlFor="input-file" onClick={() => setBoxLayout(index)}>
                        <LazyLoadImage key={`image-portifolio-create-${index}`} src={layout[index] ? layout[index] : ''} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                    </label>
                </div>
            )) : ''}
            <input id="input-file" type="file" accept="image/*" onChange={(e) => {callback(e.target.files)}} style={{display: "none"}} multiple={false}/>
        </>
    )
}

function CreatePortifolio(){
    const [data, setData] = useState({main_image: '', file_image: '', author: {uid: undefined, name: undefined}, name: '', slogan: '', site: '', type: 'none'})
    const [images, setImages] = useState({images: []})
    const [loading, setLoading] = useState(true)
    const [imagesFiles, setImagesFiles] = useState({files: []})
    const navigate = useNavigate()

    const callback_layout = () => {
        const newLayout = images.images
        const newLayoutFiles = imagesFiles.files
        newLayout.push(PlaceholderImage)
        newLayoutFiles.push('')
        setImages(prevState => {return {...prevState, images: newLayout}})
        setImagesFiles(prevState => {return {...prevState,files: newLayoutFiles}})
    }

    const addImageIn = (positionBox, image) => {
        const local_image = URL.createObjectURL(image[0])       
        addInFiles(image, positionBox)
        addInImages(local_image, positionBox)

        function addInImages(urlImage, position){
            const newLayout = images.images
            newLayout[[position]] = urlImage
            setImages(prevState => {return {...prevState, images: newLayout}})

        }
        function addInFiles(file, postion){
            const newLayoutFile = imagesFiles.files
            newLayoutFile[[postion]] = file
            setImagesFiles(prevState => {return {...prevState, files: newLayoutFile}})
        }
    }

    const setMainImage = (image) => {
        const local_image = URL.createObjectURL(image[0])        
        setData(prevState => {return {...prevState, main_image: local_image, file_image: image}})
    }

    async function createPage(){
        const AuthorData = await new USER().getDataUser()
        if(AuthorData.user){
            setData(prevState => {return {...prevState, author: {uid: AuthorData.data.id, name: AuthorData.data.data.name}}})
        }        
    }

    async function publish(){
        if(data.file_image !== '' && data.author.uid !== undefined && data.type !== 'none' && data.slogan !== '' && data.name !== '' && imagesFiles.files.length !== 0){
            setLoading(false)
            const images = verifyAndTreatImages()

            const isCreate = await new ADMINPAGE().createPortifolio(data, images)
            if(isCreate){
                navigate('/portifolio')
            }else{
                setLoading(true)
                alert('Erro!')
            }

        }else{
            console.error(data)
        }
        
        function verifyAndTreatImages(){
            const images = imagesFiles.files
            const trated = []
            for (let i = 0; i < images.length; i++) {
                if(images[i] !== ''){
                    trated.push(images[i])
                }
            }

            return trated
        }
    }

    useEffect(() => {createPage(); sizeType(); new USER().isAdm(res => res ? '' : navigate('/login'))},[]) 

    return (
        <>
            {loading ? (
                <>
                    <main id="main_showPortifolio">
                        <Header icon={0} color={"transparent"}/>
                        <label htmlFor="input-main-image">
                            <div className="container-head">
                                <LazyLoadImage style={{'objectFit': data.main_image !== '' ? 'cover' : 'scale-down'}} src={ data.main_image !== '' ? data.main_image : PlaceholderImage} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                                <h1 className="text-titleProject"> {data.name} </h1>
                            </div>
                        </label>
                        <input id="input-main-image" type="file" accept="image/*" onChange={(e) => {setMainImage(e.target.files)}} style={{display: "none"}} multiple={false}/>

                        <div className="container-imageProject" id="style-create-image-project">
                            <span className="text-titleCompleteName">Nome:  <input type="text" value={data.name} onChange={(e) => {setData(prevState => {return {...prevState, name: e.target.value}})}} /></span>
                            <span className="text-titleCompleteName">Slogan: <input type="text" value={data.slogan} onChange={(e) => {setData(prevState => {return {...prevState, slogan: e.target.value}})}} /></span>
                            <hr />
                            <div className="container-allImages">
                                <CreateLayout layout={images.images} callbackImage={addImageIn}/>
                                <LineAddSetImage styleOnly={true} layout_select={callback_layout}/>
                            </div>
                        </div>  

                        <div className="container-moreInformation">
                            <span className="text-titleInformation">Informações</span>
                            <div className="content-informationIn">
                                <span className="text-information"><b>Cliente</b>: <i>{data.name}</i>, {data.slogan} </span>
                                <span className="text-information" id="style-create-text-information"><b>Ramo</b>: 
                                    <select value={data.type} onChange={(e) => {setData(prevState => {return {...prevState, type: e.target.value}})}}>
                                        <option value="none">SELECIONAR</option>
                                        <option value="Design">Design</option>
                                    </select>
                                </span>
                                
                                <div className="button-informationSite">Site:  <input type="text" value={data.site} onChange={(e) => {setData(prevState => {return {...prevState, site: e.target.value}})}} /> </div>
                                {/* <div className="container-socialInformation">
                                    {data.data.information.socialMedia.instagram ? <a href={`www.instagram/${data.data.information.socialMedia.instagram}`}><div className="box-socialInformation"><img src="" alt="" /></div></a> : ''}
                                    {data.data.information.socialMedia.facebook ? <a href={`www.facebook/${data.data.information.socialMedia.facebook}`}><div className="box-socialInformation"><img src="" alt="" /></div></a> : ''}
                                    {data.data.information.socialMedia.linkedin ? <a href={`www.LinkedIn/${data.data.information.socialMedia.linkedin}`}><div className="box-socialInformation"><img src="" alt="" /></div></a> : ''}
                                </div> */}
                            </div>
                        </div>
                        <button onClick={() => publish()} className="button-save">salvar e publicar</button>
                    </main>
                </>
            ) : <Loading style={{marginTop: '100%'}}/>}
        </>

    )
}

export default CreatePortifolio
