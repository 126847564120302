import { useState } from "react";
import "../../scss/module/LineCreateLayout.scss"

export function LineAddSetImage({layout_select, styleOnly}){
    const [isSelect, setIsSelect] = useState(false)

    function OpenLayoutChoose(){
        setIsSelect(!isSelect)
    }   

    function selectLayout(layout){
        setIsSelect(false)
        layout_select(layout)
    }

    return (
        <>
            <div className="container-line-layout">
                <div className="line"></div>
                <button className="content-ball-plus" onClick={() => OpenLayoutChoose()}>+</button>
            </div>

            <div className="container-select-layout" style={{display: isSelect ? 'flex' : 'none'}}>
                {!styleOnly ? (
                    <>
                        <button onClick={() => selectLayout(0)} className="container-select grup-sytle">
                            grup layout
                        </button>
                        <div className="vertical_line"></div>
                    </>
                ): ''}
                <button onClick={() => selectLayout(1)}  className="container-select solo-sytle">
                    solo layout
                </button>
            </div>
        </>
    )
}