import React, { useState, useEffect } from 'react'
import PhotoAlbum from "react-photo-album";
import { useNavigate } from "react-router-dom";

function Scroll(props) {
  const [il, setIl] = useState([])
  const [paginationRef, setPaginationRef] = useState([])
  const [items, setItems] = useState([{src: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/d8dcd7160049989.63ac023c27c16.jpg",width: 800,height: 600}])
  const [page, setPage] = useState(0)
  const navigate = useNavigate();

  function buildPagination(ilustration){
    ilustration = il[0] ? il : ilustration

    var pagination = [[]]
    var selectPagination = 0
    var countForAddPagination = 0

    for (let i = 0; i < ilustration.length; i++) {
        if(countForAddPagination <= props.limit){
          pagination[selectPagination].push(ilustration[i])
          countForAddPagination++
        }else{
          pagination.push([])
          selectPagination++
          countForAddPagination = 0
        }
    }

    setPaginationRef(pagination)
    setItems(constructItemForGallery(pagination, page))
  } 

  function constructItemForGallery(pagination, page){
    const newItem = pagination[page].map((item, i) => {
        return {
            src: item.data.images[0],
            width: 100,
            height: 100,
            key: i,
            alt: item.data.projectName,
            title: item.data.projectName,
            id: item.id
        }
    })

    return newItem
  }

  const fetchData = (page) => {
    if(paginationRef[page]){
        const newItems = constructItemForGallery(paginationRef, page)

        setItems([...items, ...newItems])
      }
  }

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight

    if (scrollTop + clientHeight + 403 >= scrollHeight) {
        if(page < (paginationRef.length - 1)){
        setPage(page + 1)
      }
    }
  }

  function openProject(index){
    const productSelect = items[index]
    navigate(`/show/inspiration/${productSelect.title.replace(/ /g, "+")}/${productSelect.id}`)
  }

  useEffect(() => {
    setIl(props.itens)
    buildPagination(props.itens)
  },[props.itens])

  useEffect(() => {
    fetchData(page)
  }, [page])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [items])

  return (
    <div className='container-createInspiration'>
        <PhotoAlbum layout="masonry" spacing={4} columns={2} photos={items} onClick={({ index }) => {openProject(index)}}/>
    </div>
  )
}

export default Scroll