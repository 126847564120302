import {firestore} from './QUERY'
import {IMAGE} from '../module/processing.data'

export class ADMINPAGE{
    async createInspiration(data, layoutImage){
        const [coverImage, images] = await this.uploadImages(layoutImage, data.file_image, 'inspiration')

        const dataForBd = {
            author: data.author.name,
            codeSearch: this.createCode(),
            coverImage: coverImage,
            images: images,
            information: {
                briefing: data.briefing,
                timeframe: {
                    day: data.time.day ? data.time.day : 0,
                    month: data.time.month ? data.time.month : 0
                },
                type: data.type,
                vall: data.vall
            },
            projectName: data.projectName ? data.projectName : ''
        }

        const isCreate = await new firestore().insertData({bd: 'inspiration', insert_data: dataForBd})
        if(isCreate){
            return isCreate
        }else{
            return false
        }


    }

    async  uploadImages(layoutImages, coverImage, bd){
        const layoutRef = []
        for (let i = 0; i < layoutImages.length; i++) {
            const Indeximage = layoutImages[i];
            if(Indeximage.length === 2){
                layoutRef.push({})
                for (let j = 0; j < Indeximage.length; j++) {
                    const image = Indeximage[j];
                    const refImage = await new IMAGE().upload(image, bd)
                    layoutRef[i][j] = (refImage)
                }
            }else{
                const refImage = await new IMAGE().upload(Indeximage, bd)
                layoutRef.push(refImage)
            }
        }

        const refCoverImage = await new IMAGE().upload(coverImage, bd)

        
        return [refCoverImage, layoutRef]
    }
     createCode(){
        const createBigNumberCode = Math.floor(Math.random()*(10000000 - 10000 + 1) + 10000)
        return `#${createBigNumberCode}`
    }

    async createPortifolio(data, layoutImages){
        const [coverImage, images] = await this.uploadImages(layoutImages, data.file_image, 'ilustrations')

        const dataForBd = {
            codeSearch: this.createCode(),
            coverImage: coverImage,
            images: images,
            nameProject: `${data.name}, ${data.slogan}`,
            information: {
                author: data.author.uid,
                clientName: data.name,
                authorName: data.author.name,
                socialMedia: {
                    facebook: '',
                    instagram: '',
                    linkedin: ''
                },
                website: data.site,
                type: data.type,
            },
            typeProject: 'Logo Mockup',
        }

        const isCreate = await new firestore().insertData({bd: 'portfolio', insert_data: dataForBd})
        if(isCreate){
            return isCreate
        }else{
            return false
        }

    }

    async remove(uid, images, subImage, bd, bucket){
        const removePublish = await new firestore().deleteData({bd, id: uid})
        if(removePublish){
            const removeImages = await new IMAGE().remove(images, true, bucket)
            const removeSubImages = await new IMAGE().remove(subImage, false, bucket)
            if(removeImages && removeSubImages){
                return [true, true]
            }else{
                return [true, false]
            }
        }else{
            return [false, false]
        }
    }
}