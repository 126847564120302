import React, {Component} from "react";
import SearchIcon from "../../images/icon/SeachIcon.svg"
import "../../scss/module/Search.scss"
class Search extends Component {
    constructor(props){
        super(props)
        this.state = {
            searchFild: ""
        }
    }
   
    componentDidUpdate(prevState, prevProps){
        if(this.state.searchFild !== prevState.searchFild){
            this.props.getSeachFild(this.state.searchFild)
        }
    }

    render(){
        return (
            <>
                <div className="container-search">
                    <img src={SearchIcon} alt="Search" />
                    <input type="text" className="input-search" placeholder="Pesquisa" value={this.state.searchFild} onChange={(e) => this.setState({searchFild: e.target.value})}/>
                </div>
            </>
        )
    }
}

export default Search