/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom'
import {ADMINPAGE, USER} from '../../functions/functions'
import {sizeType} from "../../functions/mainFunction"
import {Header, LineAddSetImage} from "../../module/group"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from "../../images/icon/IconImage.svg"
import "../../scss/min/show.inspiration.scss"


function CreateLayout({layout, callbackImage}){
    const [boxLayout, setBoxLayout] = useState([0,0]) // index, subindex

    function callback(file){
        if(file){
            callbackImage(boxLayout, file)
        }
    }

    return (
        <>
         {layout.length !== 0 ? layout.map((data, index) => {
            if(data.length === 2){
                return (
                    <div className="box-Images">
                        <div className="content-break">
                            <label htmlFor="input-file" onClick={() => setBoxLayout([index, 0])}>
                                <LazyLoadImage key={`image-grup-${index}-sub-1`} src={layout[index][0] ? layout[index][0] : ''} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                            </label>
                        </div>
                        <div className="content-break">
                            <label htmlFor="input-file" onClick={() => setBoxLayout([index, 1])}>
                                <LazyLoadImage  key={`image-grup-${index}-sub-2`} src={layout[index][1] ? layout[index][1] : ''} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                            </label>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="box-Images">
                        <label htmlFor="input-file" onClick={() => setBoxLayout([index])}>
                            <LazyLoadImage onClick={() => callback([index, 0])} key={`image-solo-${index}`} src={layout[index] ? layout[index] : ''} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                        </label>
                    </div>
                )
            }
        }) : ''}

            <input id="input-file" type="file" accept="image/*" onChange={(e) => {callback(e.target.files)}} style={{display: "none"}} multiple={false}/>
        </>
    )
}

function CreateInspiration(){
    const [data, setData] = useState({main_image: '', file_image: '', author: {uid: '', name: ''}, projectName: '', type: 'none', briefing: '', vall: '', time: {month: 0, day: 0}})
    const [layoutImagem, setLayoutImage] = useState({layout: []})
    const [layoutImagesFile, setLayoutImagesFile] = useState({layout: []})
    const navigate = useNavigate()

    const callback_layout = (layoutSelect) => {
        const newLayout = layoutImagem.layout
        const newLayoutFile = layoutImagesFile.layout
        if(layoutSelect){
            newLayout.push([PlaceholderImage])
            newLayoutFile.push([''])
        }else{
            newLayout.push([[PlaceholderImage], [PlaceholderImage]])
            newLayoutFile.push([[''], ['']])
        }
        setLayoutImage(prevState => {return {...prevState, layout: newLayout}})
        setLayoutImagesFile(prevState => {return {...prevState, layout: newLayoutFile}})
    }

    const setMainImage = (image) => {
        const local_image = URL.createObjectURL(image[0])        
        setData(prevState => {return {...prevState, main_image: local_image, file_image: image}})
    }

    const addImageIn = (positionBox, image) => {
        const newLayout = layoutImagem.layout
        const newLayoutFile = layoutImagesFile.layout
        const local_image = URL.createObjectURL(image[0])        
        if(positionBox[1] === 0 || positionBox[1] === 1){
            newLayout[positionBox[0]][positionBox[1]] = local_image
            newLayoutFile[positionBox[0]][positionBox[1]] = image
        }else{
            newLayout[positionBox[0]] = local_image
            newLayoutFile[positionBox[0]] = image
        }

        setLayoutImage(prevState => {return {...prevState, layout: newLayout}})
        setLayoutImagesFile(prevState => {return {...prevState, layout: newLayoutFile}})

    }

    async function createPage(){
        const AuthorData = await new USER().getDataUser()
        if(AuthorData.user){
            setData(prevState => {return {...prevState, author: {uid: AuthorData.data.id, name: AuthorData.data.data.name}}})
        }        
    }

    async function publish(){
        if(data.file_image !== '' && data.author.uid !== '' && data.type !== 'none' && data.briefing !== undefined && data.vall !== '' && data.time.day !== 0 && layoutImagesFile.layout.length !== 0){
            const images = verifyAndTreatImages()

            const isCreate = await new ADMINPAGE().createInspiration(data, images)
            if(isCreate){
                navigate('/inspiration')
            }else{
                alert('Erro!')
            }

        }else{
            console.error(data)
        }
        
        function verifyAndTreatImages(){
            const images = layoutImagesFile.layout
            const trated = []
            for (let i = 0; i < images.length; i++) {
                if(images[i].length === 2){
                    if(images[i][0] != '' && images[i][1] != ''){
                        trated.push([images[i][0], images[i][1]])
                    }else{
                        trated.push(images[i][0] !== '' ? images[i][0] : images[i][1])
                    }
                }else{
                    if(images[i] !== PlaceholderImage){
                        trated.push(images[i])
                    }
                }
            }

            return trated
        }
    }


    useEffect(() => {createPage(); sizeType(); new USER().isAdm(res => res ? '' : navigate('/login'))},[])


    return (
            <main id="main_showInspiration">
                <Header icon={0} color={"transparent"}/>

                <label htmlFor="input-main-image">
                    <div className="container-head">
                            <LazyLoadImage style={{'objectFit': data.main_image !== '' ? 'cover' : 'scale-down'}} src={ data.main_image !== '' ? data.main_image : PlaceholderImage} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                        <div className="content-createBy">
                            <span className="text-createBy">Create By {data.author.name}</span>
                        </div>
                    </div>
                </label>
                <input id="input-main-image" type="file" accept="image/*" onChange={(e) => {setMainImage(e.target.files)}} style={{display: "none"}} multiple={false}/>
                

                <div className="container-imageProject">
                    <div className="container-allImages">
                        <CreateLayout layout={layoutImagem.layout} callbackImage={addImageIn}/>

                        <LineAddSetImage layout_select={callback_layout} />
                    </div>
                </div>  

                <div className="container-moreInformation style-showInspiration">
                    <span className="text-titleInformation">Informações da Criação</span>
                    <div className="content-informationIn">
                        <div className="content-informationCon">
                            <span className="text-informationCon">TIPO:</span>
                            <div className="button-informationCon" style={{backgroundColor: "#2482C4"}}>
                                <select onChange={(e) =>  setData(prevState => {return {...prevState, type: e.target.value}})} value={data.type}>
                                    <option value="none" disabled>SELECIONAR</option>
                                    <option value="Empresa Fictícia">Empresa Fictícia</option>
                                    <option value="Design">Design</option>
                                    <option value="Fotográfia">Fotografia</option>
                                    <option value="Pagina WEB">Pagina WEB</option>
                                    <option value="Produto">Produto</option>
                                </select>
                            </div>
                        </div>
                        <span className="text-titleInformation style-typeSeparation">BRIEFING <p>(Fictício)</p></span>
                    
                        <textarea value={data.briefing} onChange={(e) =>  setData(prevState => {return {...prevState, briefing: e.target.value}}) } className="paragraph-artigleCreate textarea-style">
                            
                        </textarea>
                        <div className="content-informationCon">
                            <span className="text-informationCon">Prazo estimado:</span>
                            <button className="button-informationCon" style={{backgroundColor: "#316BC5"}}>
                                <input type="number" style={{backgroundColor: "#2c61af"}} value={data.time.month} onChange={(e) =>  setData(prevState => {return {...prevState, time: {month: e.target.value}}})}/>    
                                meses e 
                                <input type="number" style={{backgroundColor: "#2c61af"}} value={data.time.day} onChange={(e) =>  setData(prevState => {return {...prevState, time: {day: e.target.value}}})}/>    
                                dias</button>
                        </div>
                        <div className="content-informationCon">
                            <span className="text-informationCon">Valor do Projeto: </span>
                            <button className="button-informationCon" style={{backgroundColor: "#77B72A"}}>
                                R$ 
                                <input type="number" value={data.vall} style={{backgroundColor: "#639428", width: '100%'}} onChange={(e) =>  setData(prevState => {return {...prevState, vall: e.target.value}})}/>    
                            </button>
                        </div>
                        <div className="content-informationCon">
                            <span className="text-informationCon">Nome do Projeto: </span>
                            <button className="button-informationCon" >
                                <input type="text" value={data.projectName} style={{backgroundColor: "#b85dbb", width: '100%'}} onChange={(e) =>  setData(prevState => {return {...prevState, projectName: e.target.value}})}/>    
                            </button>
                        </div>
                    </div>
                </div>

                <button onClick={() => publish()} className="button-save">salvar e publicar</button>
            </main>
    )
}

export default CreateInspiration
