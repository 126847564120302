import { getStorage, ref, getDownloadURL, deleteObject  } from "firebase/storage";
import {db} from '../../lib/firebase.config/config'
import { doc, deleteDoc, collection } from 'firebase/firestore';
import {firestore} from '../functions/QUERY'
const storage = getStorage();

export async function getImageOfTheData(data, path, isArray, _imageCore, _subfolder){    
    const tagSelectImage = _imageCore ? _imageCore : 'images'
    if(isArray){
        let responseData = []
        for (let i = 0; i < data.length; i++) {
            const dataElement = data[i];

            if(dataElement.data.coverImage){
                dataElement.data.coverImage = await getImageReference(dataElement.data.coverImage, path, _subfolder)
            }

            if(Array.isArray(dataElement.data[tagSelectImage])){
                const element_image = []

                for (let j = 0; j < dataElement.data[tagSelectImage].length; j++) {
                    const image = dataElement.data[tagSelectImage][j];
                    if(checkImage(dataElement.data[tagSelectImage])){
                     
                        const [isObject, convert] = verifyAndConvert(image)
                        if(isObject){
                            element_image.push([])
                            for (let l = 0; l < convert.length; l++) {
                                element_image[j].push(await getImageReference(convert[l], path, _subfolder))
                            }
                        }else{
                            element_image.push(await getImageReference(image, path, _subfolder))
                        }

                    }
                }
                dataElement.data[tagSelectImage] = element_image
            }else{
                if(checkImage(dataElement.data[tagSelectImage])){
                    dataElement.data[tagSelectImage] = await getImageReference(dataElement.data[tagSelectImage], path, _subfolder)
                }
            }

            responseData.push(dataElement)
        }


        return responseData
    }else{
        const dataElement = data.data[tagSelectImage];
        const element_image = []

        if(data.data.coverImage){
            data.data.coverImage = await getImageReference(data.data.coverImage, path, _subfolder)
        }

        for (let j = 0; j < dataElement.length; j++) {
            const image = dataElement[j];

            if(checkImage(dataElement)){

                const [isObject, convert] = verifyAndConvert(image)
                if(isObject){
                    element_image.push([])
                    for (let l = 0; l < convert.length; l++) {
                        element_image[j].push(await getImageReference(convert[l], path, _subfolder))
                    }
                }else{
                    element_image.push(await getImageReference(image, path, _subfolder))
                }

            }
        }
        data.data[tagSelectImage] = element_image
        return data
    }

    function checkImage(image){
        if(image && image !== ''){
            return true
        }else{
            return false
        }
    }

    function verifyAndConvert(image){
        if(typeof image === 'object' && !image._key){
            const arrayTransformed = []
            Object.keys(image).forEach((key) => {
                arrayTransformed.push(image[key])
            })

            return [true, arrayTransformed]
        }else{
            return [false, null]
        }

        
    }
}

export async function getImageReference (imageReference, path, _subfolder){
    if(imageReference._key){
        const nameImage = imageReference._key.path.segments[_subfolder ? 9 : 8] // old: imageReference
        const url = await getDownloadURL(ref(storage, `${path}/${nameImage}`))
        return url
    }else{
        return false
    }
}

export async function filterByTag(data, tag){
    return data?.filter((data) => data.data.tag === tag)
}

export class IMAGE{
    // img: c://... | bucket: budget/send
    async upload(img, Mainbucket, subBucket){

        const bucket = subBucket ? `${Mainbucket}/${subBucket}` : Mainbucket
        const informationImage = await this.splitNameAndFile(img)
        await this.createRefImage(informationImage, bucket)
        let ref2
        
        if(subBucket){
            ref2 = doc(collection(db, `gs:/design-de-ponta.appspot.com/${bucket}/${informationImage[1]}`));
        }else{
            ref2 = doc(db, `gs:/design-de-ponta.appspot.com/${bucket}/${informationImage[1]}`);
        }
        
        await deleteDoc(ref2);
        return ref2
    }

    async splitNameAndFile(img){
        const image = img[0]
        return [image, image.name]
    }

    async createRefImage([file, name], bucket){
        return await new firestore().createRef({bucket: `${bucket}/`, nameFile: name, file: file})
    }

    async download (refDoc, path, _subfolder, user){
        const nameImage = refDoc._key.path.segments[_subfolder ? 9 : 8] // old: imageReference
        const filePath = `${path}/${nameImage}`;
        const fileRef = ref(storage, filePath);
    
        // Obter a URL de download
        getDownloadURL(fileRef)
        .then((url) => {
            
            // Usar fetch para obter o arquivo
            fetch(url, {
                path: `/b/design-de-ponta.appspot.com/o/${filePath}`,
                method: 'GET',
                headers: {
                  "auth": {
                    "uid": user.uid,
                    "token": {
                        "firebase": {
                            "sign_in_provider": "google.com",
                        },
                        "email": user.email,
                        "email_verified": true,
                        "phone_number": "",
                        "name": ""
                    }
                }}})
            
              .then(response => response.blob())
              .then(blob => {
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute('download', 'ref.png');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
              })
              .catch((error) => {
                console.error("Erro ao baixar o arquivo: ", error);
              });
          })
          .catch((error) => {
            console.error("Erro ao obter a URL de download: ", error);
          });
    };

    async remove(images, isArray, bucket){
        if(isArray){
            const resolve = []
            for (let i = 0; i < images.length; i++) {
                const image = images[i];

                if(image.length === 2){
                    for (let j = 0; j < image.length; j++) {
                        const subImage = image[j];
                        const nameUrlImage = getNameFile(subImage)
                        resolve.push(await storageRemove(bucket, nameUrlImage))
                    }
                }else{
                    const nameUrlImage = getNameFile(image)
                    resolve.push(await storageRemove(bucket, nameUrlImage))
                }
            }

            console.log(resolve)
            return resolve.includes(false) ? false : true
        }else{
            const nameUrlImage = getNameFile(images)
            return await storageRemove(bucket, nameUrlImage)
        }

        async function storageRemove(bucket, fileUrlName) {
            const desertRef = ref(storage, `${bucket}/${fileUrlName}`);
            return await new Promise(resolve => {
                deleteObject(desertRef).then(() => {
                    resolve(true)
                }).catch((error) => {
                    resolve(false)
                });
            })
        }

        function getNameFile(image){
            return image.split('/')[7].split('?')[0].replaceAll('%20',' ').split('%2F')[1]
        }
    }
}