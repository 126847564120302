import {ILUSTRATION, INSPIRATION} from '../functions'

async function getIlustrationForBall(res){
    // get ilustration information and yours images
    const request_ilustration = await new ILUSTRATION().getAllIlustration()
    const request_inspiration = await new INSPIRATION().getAllInspiration()
    const mix_arr = [...request_ilustration, ...request_inspiration]

    res(processingData(mix_arr))

    function processingData(data){
        const size_arr = data.length - 1
        let content = 0
        let random_1 = 0
        let random_2 = 0
        let random_3 = 0
        do{
            content++
            random_1 = Math.floor(Math.random()*(size_arr - 0 + 1) + 0)
            random_2 = Math.floor(Math.random()*(size_arr - 0 + 1) + 0)
            random_3 = Math.floor(Math.random()*(size_arr - 0 + 1) + 0)
            if(content > 10){
                break;
            }
        
        }while(!((random_1 !== random_2) && (random_1 !== random_3) && (random_2 !== random_3)))

        if(content > 10){
            return ([{id: "109u2981hs89", create: "D2P", images: ["https://img.freepik.com/vetores-gratis/astronauta-bonito-ler-livro-sobre-ilustracao-de-icone-de-desenho-animado-do-planeta-conceito-de-icone-de-tecnologia-de-ciencia-isolado-estilo-flat-cartoon_138676-2091.jpg"]}, {id: "sdn18h981h89", create: "D2P", images: ["https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa2L7hGZPPvv5VN683reZSpP1pKHAaHSrfUQ&usqp=CAU"]}, {id: "shaughs7sa89as", create: "D2P", images: ["https://mir-s3-cdn-cf.behance.net/project_modules/fs/b3b143161477543.63c9b61eaca07.png"]}])
        }else{
            return [
                {
                    id: data[random_1].id,
                    create: "D2P",
                    images: data[random_1].data.images
                },
                {
                    id: data[random_2].id,
                    create: "D2P",
                    images: data[random_2].data.images
                },
                {
                    id: data[random_3].id,
                    create: "D2P",
                    images: data[random_3].data.images
                }
            ]
            
        }
    }
}

export default getIlustrationForBall