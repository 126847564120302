import {GEMINI} from '../../lib/APIs/GEMINI/config.gemini'

const defaultModelTextAPI = "Serão passadas algumas informações em ordem dinâmica sobre um questionário. Essas informações correspondem a informações passadas por um usuário que quer fazer uma marca para a ideia ou empresa dele. Preciso que você reorganize essas informações a fim de contar uma história visando facilitar a interpretação para o design que fará a marca desse usuário. Não utilize caracteres especiais e/ou estilização de texto como: negrito, italico, underline, etc. Segue as informações:"
const typeOfBranding = ["Marca Nova", "Remodelar uma marca existente", "Tenho uma empresa, e quero uma marca"]
const optionForSelect = ["Cores e Fontes", "WEB site", "Manual da Marca", "Aplicativo IOS", "Aplicativo Android"]

export async function getResume(data){
    if(data){
        const TokenForAPI = (
            defaultModelTextAPI + `Tipo de orçamento escolhido: ${typeOfBranding[data.typeOfBranding]}.
             Descreva seu produto ou serviço: ${data.service}.
             Quem são seus clientes e/ou publico-alvo: ${data.clients.join(",")}.
             nome da empresa: ${data.nameInterprise}.
             cor preferida ou cor da sua empresa: ${data.favoriteColor}.
             Elemento que não quero: ${data.notElement.join(",")}.
             Slogan: ${data.slogan !== "" ? data.slogan : "não"}.
             Cite 3 empresas que eu gosto: não.
             Materias a serem desenvolvidos: ${data.optionsForCreate.map((vall, index) => vall === true ? (optionForSelect[index]) : '').join(",")}`
        );
    
        return {status: true, resume: await getAPI(TokenForAPI)}
    }
}

async function getAPI(token){
    const result = await GEMINI.generateContent(token);
    const response = await result.response;
    return response.text().replace("*", "");
}