import React, {useEffect, useState} from "react";
import {getIlustrationBall} from "../../functions/mainFunction"
import "../../scss/module/BallImages.scss"

function BallImages(props){
    const [ilustration, setIlustration] = useState([])
    const [createBalls, setCreateBalls] = useState([])
    const [postionsAccepted, setPostionsAccepted] = useState({
        small: [{y: 57, x: 93},{y: 71,x: 23},{x: 73,y: 110}], 
        medium: [{x: 7,y: 157},{x: 101,y: 150},{x: 78,y: 192}], 
        large: [{x: 149, y: 182},{x: 261, y: 170}]
    })

    function getBallsIlustration(res){
        getIlustrationBall(Response => {
            res(Response)
            setIlustration(Response)
        })
    }

    function CreateBalls(){
        getBallsIlustration(il => {
            if(ilustration[1] || il[1]){
                const getIlustration = (ilustration[1]) ? ilustration : il

                const informationCreate = getProps()
                var arrayBallsCreate = []
    
                for (let i = 0; i < getIlustration.length; i++) {
                    if(informationCreate.quant > i){
                        const sizeSelect =  createSizeBalls()
                        const ilustrationSelect = createIlustrationBalls(getIlustration)
                        const positionSelect = createPositionBalls(sizeSelect, informationCreate.formate, i)
    
                        arrayBallsCreate.push({id: ilustrationSelect.id, il: ilustrationSelect.il, size: sizeSelect, position: positionSelect.position})
                    
                        if(positionSelect.index){
                            removeAlreadyUsed(positionSelect.index, sizeSelect)
                        }

                        getIlustration[ilustrationSelect.index] = null
                    }
                }
    
                setCreateBalls(arrayBallsCreate)            
            }else{
                console.warn(ilustration)
            }
        })


        function createPositionBalls(size, formate, i){
            if(formate === "random"){
                const positionSelect = getPositionForSizeSelect(size)
                const randomSelect = Math.floor(Math.random() * positionSelect.position.length);      
            
                return {index: randomSelect, position: (positionSelect.position[randomSelect] ? positionSelect.position[randomSelect] : {x: 0, y: 0})}
            }else{
                return {position: formate[i]}
            }

            function getPositionForSizeSelect(size){
                let positions

                if(size === "small"){
                    positions = removeUndefined(postionsAccepted.small)
                    return {position: positions, tag: "small"}
                }else if(size === "medium"){
                    positions = removeUndefined(postionsAccepted.medium)
                    return {position: positions, tag: "medium"}
                }else{
                    positions = removeUndefined(postionsAccepted.large)
                    return {position: positions, tag: "large"}
                }

                function removeUndefined(array){
                    var arr = array.map(key => {
                        if(key === undefined || key === null || key === ""){
                            return undefined
                        }else{
                            return key
                        }
                    })
                    return arr.filter(key => key !== undefined)
                }
            }

        }

        function removeAlreadyUsed(indexSelect, size){
            const positionRef = postionsAccepted            
            let positionForRemove = postionsAccepted[size]
            positionForRemove[indexSelect] = undefined
            positionForRemove = positionForRemove.filter(key => key)
            positionRef[size] = positionForRemove
            setPostionsAccepted(positionRef)
        }

        function createIlustrationBalls(ilustration){       
            const ilustrationForUse = ilustration.filter(key => key)
            const randomSelect = Math.floor(Math.random() * ilustrationForUse.length);
            
            return {il:  ilustrationForUse[randomSelect], index: randomSelect}
        }
  
        function createSizeBalls(){
            const formate = ["small", "medium", "large"]
            const randomSelect = Math.floor(Math.random() * formate.length);
            return formate[randomSelect]
        }

        function getProps(){
            return {quant: props.quant, formate: props.formate}
        }
    }
    useEffect(() => {
        CreateBalls()
    }, []);

    return (
        <>
            <div id="container_balls" className="container-balls">
                {createBalls ? createBalls.map((ball, i) => (
                    <div key={`key-${ball.id}_${i}`} className={`box-ball style-${ball.size}`} style={{top: `${ball.position.y}px`, right: `${ball.position.x}px`, backgroundImage: `url(${ ball.il.images[0]})`}}>

                        <div className="box-createBy">
                            <span className="text-creteBy">Create by{ball.il.create}</span>
                        </div>
                    </div>  
                )) : "error"}
            </div>
        </>
    )
}

export default BallImages