import React, {useState} from "react";
import {useParams} from 'react-router-dom'
import {InputsQuis, UpBarQuis, DownBarQuis, OptionsMark, BrandingResumeIA} from "../module/components/Create.Quis.Modules"
import {Header, ShowBallImages, Loading} from "../module/group"
import {BUDGET} from '../functions/functions'
import { getResume } from "../functions/mainFunction";
import arrowCurv from "../images/icon/arrowCurv.svg"
import { Link, useNavigate } from "react-router-dom";
import "../scss/min/brandingQuiz.scss"

function BrandingNew(){
    const { codeBranding } = useParams()
    const [quizConf, setQuizConf] = useState([1, 0, 0, 0, 0])
    const [siteFild, setSiteFild] = useState('')
    const [serviceFild, setServiceFild] = useState('')
    const [clientsFild, setClientsFild] = useState('')
    const [nameEnterpriseField, setNameEnterpriseField] = useState('')
    const [imageEnterprise, setImageEnterprise] = useState(undefined)
    const [favoriteColorField, setFavoriteColorField] = useState('')
    const [notElementField, setNotElementField] = useState('')
    const [sloganField, setSloganField] = useState('')
    const [optonsMarkSelect, setOptionsMarkSelect] = useState('')
    const [resume, setResume] = useState('')
    const optionForSelect = ["Cores e Fontes", "WEB site", "Manual da Marca", "Aplicativo IOS", "Aplicativo Android"]
    const navigate = useNavigate();

    const sendForFatherSite = r => setSiteFild(r)
    const sendForFatherService = r => setServiceFild(r)
    const sendForFatherSiteClient = r => setClientsFild(r)

    const sendForFatherName = r => setNameEnterpriseField(r)
    const sendForFatherImage = r => setImageEnterprise(r)
    const sendForFatherFavoriteColor = r => setFavoriteColorField(r)
    const sendForFatherNotElement = r => setNotElementField(r)
    const sendForFatherSiteSlogan = r => setSloganField(r)

    const sendForFatherOptions = r => setOptionsMarkSelect(r)
    const buttonClicked = (index, clicked) => {
        if(index)
            NextPage(clicked)
        else
            BackPage(clicked)
    }

    function allInformationAlreadyComplete(page){
        
        function CheckProjectPage(){
            if(isNotNull(serviceFild) && isNotNull(clientsFild)){
                return true
            }else{
                return false
            }
        }

        function CheckEnterprisePage(){
            if(isNotNull(nameEnterpriseField) && isNotNull(favoriteColorField)){ // isNotNull(imageEnterprise)
                return true
            }else{
                return false
            }
        }

        function CheckOptionsPage(){
            if(isNotNull(optonsMarkSelect) && optonsMarkSelect.indexOf(true) !== -1){
                return true
            }else{
                return false
            }
        }

        function isNotNull(value){
            if(value && value !== null && value !== undefined && value !== ''){
                if(typeof myVar === 'string'){
                    return (value.replace(/ /g, "").length > 0) 
                }else{
                    return value.length > 0
                }
            }
        }

        switch(page){
            case 1:
                return CheckProjectPage();
            break;
            case 2:
                return CheckEnterprisePage();
            break;
            case 3:
                return CheckOptionsPage();
            break;
            default:
                return true
        }        
    }

    function NextPage(page){
        let AlterateLayout = [0,0,0,0,0]
        if(allInformationAlreadyComplete(page)){
            AlterateLayout[page] = 1        
            setQuizConf(AlterateLayout)
            
            if(AlterateLayout[3] === 1){
                requestResume()
            }
        }
    }

    function BackPage(page){
        let AlterateLayout = [0,0,0,0,0]
                
        if(quizConf.indexOf(1) === 0){
            navigate("/branding")
        }

        AlterateLayout[page] = 1        
        setQuizConf(AlterateLayout)

    }

    async function requestResume(){
        const dataForResume = {
            typeOfBranding: codeBranding ? codeBranding : 0,
            site: siteFild, 
            service: serviceFild,
            clients: clientsFild,
            nameInterprise: nameEnterpriseField,
            favoriteColor: favoriteColorField,
            notElement: notElementField,
            slogan: sloganField,
            optionsForCreate: optonsMarkSelect
        }
        const requestAPI = await getResume(dataForResume)
        if(requestAPI.status){
            setResume(requestAPI.resume)
        }
    }

    async function finishedQuiz(){
        console.log(nameEnterpriseField)
        if(resume && resume.length !== 0){
            const data = {
                typeOfBranding: codeBranding ? codeBranding : 0,
                site: siteFild, 
                service: serviceFild,
                clients: clientsFild,
                nameInterprise: nameEnterpriseField,
                favoriteColor: favoriteColorField,
                notElement: notElementField,
                slogan: sloganField,
                optionsForCreate: optonsMarkSelect,
                resume: resume,
                images: imageEnterprise
            }

            const request = await new BUDGET().create(data)
            if(request[0]){
                navigate(`/budget/view/${request[1]}`)
            }else{
                alert('Ops! não foi possivel completar o quiz, estamos em analize. Tente novamente mais tarde.')
            }
        }else{

        }
    }

    return (
        <>
            <Header icon={1} color={"#1A1A1A"}/>

            <main id="main_BrandingNew">
                <div id="content-quiz-2" style={{display: (quizConf[0] ? "block" : "none")}}>
                    <UpBarQuis type={1} color={"#EC6707"} textPage={"Sobre sua empresa"} title={"Responda algumas perguntas para que nós consigamos cria sua marca."}/>

                    <div className="containerInputs">
                        <InputsQuis sendForFather={sendForFatherSite} title={"Endereço do site"} required={true} type={1}/>
                        <InputsQuis sendForFather={sendForFatherService} title={"Descreva seus produtos/serviços"} required={true} type={1}/>
                        <InputsQuis sendForFather={sendForFatherSiteClient} title={"Quem são seus clientes/público-alvo?"} required={true} type={2}/>
                    </div>

                    <DownBarQuis buttonClicked={buttonClicked} page={2} back={0}/>
                </div>

                <div id="content-quiz-3" style={{display: (quizConf[1] ? "block" : "none")}}>
                    <UpBarQuis type={1} color={"#262172"} textPage={"Sobre seu projeto"} title={"Essas perguntas vão nos ajudar a saber exatamente o que você está pensando!"}/>

                    <div className="containerInputs">
                        <InputsQuis sendForFather={sendForFatherName} title={"Qual é o nome da empresa/projeto"} required={true} type={1}/>
                        <InputsQuis sendForFather={sendForFatherImage} title={"Você tem alguma imagem/símbolo?"} required={false} type={3}/>
                        <InputsQuis sendForFather={sendForFatherFavoriteColor} select={["#", "rgba", "TXT"]} title={"Sua cor preferida ou a cor da sua empresa"} required={true} type={4}/>
                        
                        <Link to=""><span className="text-downInput">Como saber o código da minha cor?</span></Link>
                        
                        <InputsQuis sendForFather={sendForFatherNotElement} title={"Há alguma cor ou elemento que você não quer?"} required={false} type={2}/>
                        <InputsQuis sendForFather={sendForFatherSiteSlogan} select={["Sim", "Não"]} title={"Você tem um slogan? Qual?"} required={true} type={4}/>
                        
                        <span className="text-downInput">Pular esta pergunta</span>
                    </div>

                    <DownBarQuis buttonClicked={buttonClicked} page={3} back={0}/>
                </div>


                <div id="content-quiz-4" style={{display: (quizConf[2] ? "block" : "none")}}>
                    <UpBarQuis type={1} color={"#77B72A"} textPage={"Criação"} title={"Essas perguntas vão nos ajudar a saber exatamente o que você está pensando!"}/>

                    <div className="containerInputs">
                        <OptionsMark mark={optionForSelect} sendForFatherOptions={sendForFatherOptions}/>
                        <span className="text-downInput">Pular esta pergunta</span>
                    </div>

                    <DownBarQuis buttonClicked={buttonClicked} page={4} back={1}/>
                </div>

                <div id="content-quiz-6" style={{display: (quizConf[3] ? "block" : "none")}}>
                    <UpBarQuis type={1} IAIcon={true} color={"#964B98"} textPage={"Resumo"} title={"Criamos uma descrição com todas as informações. Queremos saber de você!"}/>

                    <div className="containerInputs">
                       {resume ? <BrandingResumeIA resume={resume}/> : <Loading key={'loading-branding-ia'}/>}
                    
                        <div className="containerResponse">
                            <hr id="line-quiz" />
                            <span className="text-response">Essa descrição que criamos, faz sentido para você?</span>
                            <span className="text-response-sub">você pode voltar e modificar suas respostas.</span>
                        </div>

                    </div>

                    <DownBarQuis buttonClicked={buttonClicked} page={5} back={2}/>
                </div>

                <div id="content-quiz-5" style={{display: (quizConf[4] ? "block" : "none")}}>
                    <div className="container-content-finish">
                        <div className="container-finishTitle">
                            <span className="text-finishTitle"><p>Ufa!</p> finalmente acabamos, as informações já estão sendo enviadas, traremos noticias o mais rápido possível!</span>
                            <hr />
                            <ShowBallImages quant={2} formate={[{x: 28, y: 68}, {x: 145, y: 125}]}/>
                        </div>
                        <div className="container-contentEmail">
                            <span className="text-email">Email de contato: </span>
                            <div className="fakeInput-email">email cadastrado na sua conta</div>
                        </div>
                    </div>
                    <button onClick={(e) => finishedQuiz()} className="button-finish">Terminar</button>
                    <span onClick={(e) => BackPage(2)} className="text-alterateInformation"><img src={arrowCurv} alt="" />Mudar alguma informação</span>
                </div>  


            </main>

        </>
    )
}

export default BrandingNew