import {firestore} from './QUERY'
import {IMAGE} from '../module/processing.data'
import {USER} from './user'

export class BUDGET{
    async create(data){
        const user = await new USER().getDataUser()
        if(user.user){
            let refImage = ''
            if(data.images !== undefined && data.images !== ''){
                refImage = await new IMAGE().upload(data.images, 'budget', 'send')
            }

            const insertData = {
                budget: false,
                budgetFile: '',
                color: data.favoriteColor,
                descriptionInterprise: data.service,
                designSelect: 'false',
                forMake: data.optionsForCreate,
                image: refImage,
                likeInterprise: [],
                nameProject: data.nameInterprise,
                notIncludeElement: data.notElement,
                refImage: refImage,
                resume_ia: data.resume,
                slogan: data.slogan,
                status: 0,
                targetAudience: data.clients,
                typeBanding: data.typeOfBranding,
                userCreate: user.data.id,
                website: data.site
            }

            const response = await new firestore().insertData({bd: 'budget', insert_data: insertData})
            if(response){
                return [true, response]
            }else{
                return false
            }
        }else{  
            return false
        }
    }

    async getAllBudgets(){
        const budgetUser = await new firestore().getWhere({bd: 'budget', where: ['designSelect', 'false']})
        const processingData = await this.getDataUserOfBudget(budgetUser)

        return [true, processingData]
    }

    async getBudgetOfDesigner(){
        const user = await new USER().getDataUser()
        if(user.user && user.data.data.typeUser === 1){
            const budgetUser = await new firestore().getWhere({bd: 'budget', where: ['designSelect', user.data.id]})
            const processingData = await this.getDataUserOfBudget(budgetUser)
            
            return [true, processingData, user.data]
        }else{
            return [false, false]
        }
    }

    async getDataUserOfBudget(datas){
        const request_data = []
        for (let i = 0; i < datas.length; i++) {
            const element = datas[i];

            const user = await new USER().getDataUser(element.data.userCreate, true)
            
            request_data.push(datas[i])
            if(user.user){
                request_data[i].data.userCreate =user.data
            }
        }

        return request_data
    }

    async getBudgetOfUser(){
        const user = await new USER().getDataUser()
        if(user.user){
            const budgetUser = await new firestore().getWhere({bd: 'budget', where: ['userCreate', user.data.id]})
            return [true, budgetUser, user.data]
        }else{
            return [false, false]
        }
    }

    async getEspecificBudgetOfUser(id){
        const user = await new USER().getDataUser()
        if(user.user){
            let budgetOfUser;
            if(user.data.data.typeUser === 1){
                budgetOfUser = await new firestore().getWhere({bd: 'budget', where: ['documentID', id]})
            }else{
                budgetOfUser = await new firestore().getWhere({bd: 'budget', where: ['userCreate', user.data.id]})
            }

            return [true, budgetOfUser.filter(budget => budget.id === id), user.data]
        }else{
            return [false, false]
        }
    }

}
