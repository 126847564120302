import React, {Component} from "react";

class Phrase extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return (
            <>
            
            </>
        )
    }
}

export default Phrase