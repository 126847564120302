import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyAe_A8E7vzddD3i5W_3TSXItg5CpCiGYbs",
  authDomain: "design-de-ponta.firebaseapp.com",
  projectId: "design-de-ponta",
  storageBucket: "design-de-ponta.appspot.com",
  messagingSenderId: "782012833092",
  appId: "1:782012833092:web:766a2402d3c6ff1a0a0b11",
  measurementId: "G-RJQRP5CTLK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const db = getFirestore(app)
const auth = getAuth(app);

// remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // for dev

// valores padrões
remoteConfig.defaultConfig = {
  "developer": true
};

export {analytics, db, auth, storage, remoteConfig}