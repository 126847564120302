import React, {Component} from "react";
import { Link } from "react-router-dom";
import {Menu} from "../group"
import D2PColorIcon from "../../images/icon/d2pColorIcon.png"
import D2PWhiteIcon from "../../images/icon/d2pWhiteIcon.png"
import "../../scss/module/Header.scss"

class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            menuType: false
        }
    }

    IconD2PSelect = () => {
        if(this.props.icon && this.props.icon === 1){
            return (<Link to="/"><img src={D2PColorIcon}  alt="" className="img-IconD2P"/></Link>)
        }else{
            return (<Link to="/"><img src={D2PWhiteIcon}  alt="" className="img-IconD2P"/></Link>)
        }
    }

    alterateMenuType(e){
        var type = !this.state.menuType
        this.setState({menuType: type})
    }

    modifyMenu = r => {
        this.setState({menuType: r})
    }

    render(){
        return (
            <>
                <header style={{backgroundColor: this.props.color}}>
                    {this.IconD2PSelect()}
                    <div onClick={(e) => this.alterateMenuType(e)} className="container-hamburguerMenu"></div>
                </header>
                <Menu modifyMenu={this.modifyMenu} open={this.state.menuType}/>
            </>
        )
    }
}

export default Header