import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Header, ContainerUpCase} from "../module/group"
import {sizeType} from "../functions/mainFunction"
import {USER, BUDGET} from '../functions/functions'
import iconBrandingCastle from "../images/icon/iconBrandingCastle.png"
import iconBrandingCreate from "../images/icon/iconBrandingCreate.png"
import iconBrandingModify from "../images/icon/iconBrandingModify.png"
import "../scss/min/myAccount.scss"


function BoxMainRequest({typeRequest, nameRequest, statusRequest, informationRequest, onClick}){
    const colorStatus = ["#E4C312", "#3e75b4", "#77B72A", "#B20C0C"]
    function GetImageRequest({typeRequest}){
        function GetImage(){
            switch(typeRequest){
                case 1: 
                    return <img src={iconBrandingCreate} alt="create new" />
                break;
                case 2: 
                    return <img src={iconBrandingModify} alt="modify logo" />
                break;
                default:
                    return <img src={iconBrandingCastle} alt="create new logo" />
            }
        }
    
        return (
            <GetImage/>
        )
    }
    
    function ContentInformationUserRequest({textProps, textValue, style}){
        if(!style){
            style = undefined;
        }
        return(
            <>
                <div className="content-informationProps">
                    <span className="text-informationProps">{textProps}</span>
                    <span className="content-informationValue" style={style}>{textValue}</span>
                </div>
            </>
        )
    }

    function getNameStatusRequest(statusRequest){
        switch(statusRequest){
            case 0:
                return "Em analize"
            break;
            case 1:
                return "Criando"
            break;
            case 2:
                return "Concluído"
            break;
            default:
                return "Finalizado"
        }
    }

    return (
        <>
            <div className="box-mainRequest">
                <div className="content-informationRequest">
                    <GetImageRequest typeRequest={typeRequest}/>
                    <div className="content-nameAndStatus">
                        <span className="text-nameRequest">{nameRequest}</span>
                        <span className="text-statusRequest">status: <i style={{color: colorStatus[statusRequest]}}>{getNameStatusRequest(statusRequest)}</i></span>
                    </div>
                </div>

                <div className="contentInformationUserRequest">
                    <ContentInformationUserRequest style={{textDecoration: "underline"}} textProps={"Contato:"} textValue={informationRequest.email}/>
                    <ContentInformationUserRequest style={{textDecoration: "underline"}} textProps={"Nome da empresa/projeto: "} textValue={informationRequest.projectName}/>
                </div>

                {statusRequest === 1 ? (
                    <button onClick={onClick} title="Visualizar Orçamento" className="button-downloadRequest">Visualizar Orçamento</button>
                ):""}
            </div>
        </>
    )
}

function Portifolio(){
    const [budgets, setBudgets] = useState([])
    const [user, setUser] = useState()
    const statusText = ['Requisição de orçamento', 'Em analize', "Criando"]
    const navigate = useNavigate();

    function openRequest(idRequest){
        navigate(`/budget/view/${idRequest}`)
    }

    async function construct(){
        const request = await new BUDGET().getBudgetOfUser()
        if(request[0]){
            setBudgets(await processingData(Array.isArray(request[1]) ? request[1] : [request[1]]))
            setUser(request[2])
        }
    }

    async function processingData(_budgets){
        if(_budgets){
            const budget_status_0 = _budgets.filter((_budget) => _budget.data.status === 0)
            const budget_status_1_2 = _budgets.filter((_budget) => _budget.data.status === (1 || 2))
            return [Array.isArray(budget_status_0) ? budget_status_0 : [budget_status_0], Array.isArray(budget_status_1_2) ? budget_status_1_2 : [budget_status_1_2]]
        }else{
            return [false, false]
        }
    }

    useEffect(() => {
        sizeType()
        new USER().isLogWithResponse(res => !res ? navigate('/login') : '')
        construct()
    },[])

    return (
        <>
            <Header icon={2} color={"#262172"}/>

            <main id="myAccount_main">
                <ContainerUpCase fontSize={"2.3em"} textColor={"#EC6707"} lineColor={"#EC6707"} text={"Crie, acompanhe, personalize! Essa é a sua conta."} />                

                {(budgets[0] && budgets[0].length) !== 0 ? (
                    <div className="container-mainRequest">
                        {budgets[0]?.map((budget, index) => {
                            return (
                                <BoxMainRequest key={`budget-${index}-${budget.data.nameProject}`} onClick={(e) => openRequest(budget.id)}typeRequest={budget.data.typeBanding} nameRequest={statusText[budget.data.status]} statusRequest={budget.data.status}informationRequest={{email: user.data.tell, projectName: budget.data.nameProject}}/>
                            )
                         })}
                    </div>
                ):""}

                {(budgets[1] && budgets[1].length) !== 0 ? (
                    <div className="container-acceptedRequest">
                        {budgets[1]?.map((budget, index) => {
                            return (
                                <BoxMainRequest key={`budget-${index}-${budget.data.nameProject}`} onClick={(e) => openRequest(budget.id)}typeRequest={budget.data.typeBanding} nameRequest={statusText[budget.data.status]} statusRequest={budget.data.status} informationRequest={{email: user.data.tell, projectName: budget.data.nameProject}}/>
                            )
                        })}
                    </div>
                ): ""}
            </main>
        </>
    )
}

export default Portifolio