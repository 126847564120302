import {analytics, db, auth, storage, remoteConfig} from '../../lib/firebase.config/config'

import {__getData, __getDataEspecific} from "../function.firebase/get.data"
import __addData from "../function.firebase/add.data"
import __updateData from "../function.firebase/update.data"
import __deleteData from "../function.firebase/delete.data"
import __getUser from "../function.firebase/get.user"
import __createRef from "../function.firebase/create.ref"
const dataNotIncluded = ['user']

export function verifyIfExistData(nameData){
    if(nameData && !dataNotIncluded.includes(nameData)){
        if(JSON.parse(sessionStorage.getItem(`cach-${nameData}`))){
            return JSON.parse(sessionStorage.getItem(`cach-${nameData}`))
        }else{
            return false
        }
    }else{
        return false
    }
}

export function setItemInStorage(nameData, data){
    if(nameData && !dataNotIncluded.includes(nameData)){
        const processingData = JSON.stringify(data)
        sessionStorage.setItem(`cach-${nameData}`, processingData);
        return  true
    }else{
        return false
    }
}

export class firestore{
    async get(data){
        return await new Promise((resolve, reject) => {
            const cachData = verifyIfExistData(data.bd)
            if(cachData){
                resolve(cachData)
            }else{
                __getData(data, db, (response)=>{
                    if(response){
                        setItemInStorage(data.bd, response)
                        resolve(response)
                    }else{
                        reject(false)
                    }
                })
            }
        })
    }
    async getWhere(data){
        return await new Promise((resolve, reject) => {
            __getDataEspecific(data, db, (response)=>{
                if(response){
                    resolve(response)
                }else{
                    resolve(false)
                }
            })
        })
    }
    async insertData(data){
        return await new Promise((resolve, reject) => {
            __addData(data, db, (response)=>{
                if(response){
                    sessionStorage.removeItem(`cach-${data.bd}`)
                    resolve(response)
                }else{
                    resolve(false)
                }
            })
        })
    }

    async updateData(data){
        return await new Promise((resolve, reject) => {
            __updateData(data, db, (response)=>{
                if(response){
                    sessionStorage.removeItem(`cach-${data.bd}`)
                    resolve(response)
                }else{
                    resolve(false)
                }
            })
        })
    }

    async deleteData(data){
        return await new Promise((resolve, reject) => {
            __deleteData(data, db, (response)=>{
                if(response){
                    sessionStorage.removeItem(`cach-${data.bd}`)
                    resolve(response)
                }else{
                    resolve(false)
                }
            })
        })
    }
    async getUser(){
        return await new Promise((resolve, reject) => {
            __getUser((response)=>{
                if(response){
                    resolve(response)
                }else{
                    reject(false)
                }
            })
        })
    }
    async createRef(data){
        return await new Promise((resolve, reject) => {
            __createRef(data, (response)=>{
                if(response){
                    resolve(response)
                }else{
                    reject(false)
                }
            })
        })
    }
}

export {remoteConfig}

/*
    data: {
        'bd': "",
        'select': "",
        'insert_data': {'name': "", 'email': ""},
        'update': {'id': "", 'data': {'name': "", 'email': ""}},
        'limit': "",
        'where': ""
        'ref': {'bucket': 'blog', nameFile: 'caminhadaNaRua', file: ''}
    }
*/

