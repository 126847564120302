import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import PhotoAlbum from "react-photo-album";
import {ILUSTRATION, INSPIRATION} from '../functions/functions'
import {Header, Footer, ShowBallImages, ContainerUpCase} from "../module/group"
import {sizeType} from "../functions/mainFunction"
import arrowCurv from "../images/icon/arrowCurv.svg"
import "../scss/min/index.scss"

function Index(){
    const [imagesIns, setImagesIns] = useState([])
    const [imagesIl, setImagesIl] = useState([])

    async function constructPage(){
        const request_ilustration = await new ILUSTRATION().getAllIlustration()
        const request_inspiration = await new INSPIRATION().getAllInspiration()
        
        setImagesIns(selectOnlyImages(request_inspiration))
        setImagesIl(sortSelect(request_ilustration))

        function sortSelect(arr_data){
            const lengthData = (arr_data.length - 1)
            if(arr_data.length > 2){
                let random_1 = Math.floor(Math.random()*(lengthData - 0 + 1) + 0)
                let random_2 = gerenateNotSameNumber(random_1, lengthData)

                return [arr_data[random_1], arr_data[random_2]]
            }else{
                return arr_data
            }

            function gerenateNotSameNumber(number, max){
                let inGenerateContention = 0
                let random = 0
                do {
                    random = Math.floor(Math.random()*(max - 0 + 1) + 0)
                    inGenerateContention++
                    
                } while ((random === number) || inGenerateContention > 10);
            
                return random
            }
        }

        function selectOnlyImages(arr_data){
            const photos = []
            for (let i = 0; i < arr_data.length; i++) {
                const data = arr_data[i];
                photos.push({
                    src: data.data.images[0],
                    width: 1600,
                    height: 900
                })
            }
            return photos
        }
    }

    useEffect(() => {
        sizeType()
        constructPage()
    }, []);

    return (
        <>
            <Header icon={1} color={"#4F3CC7"}/>

            <main id="main_index">

                <div className="container-upCase">
                    <ContainerUpCase textColor={"#3590C2"} lineColor={"#3590C2"} text={"Um ótimo design faz sua marca crescer"} />                
                    <ShowBallImages quant={3} formate={[{x: 47, y: 86}, {x: 77, y: 177}, {x: 209, y:170}]}/>
                </div>
                <Link to="/portifolio"><button className="button-externalLink">Portifólio D2P</button></Link>

                <hr className="LinePage" />

                <div className="container-showImageIlustration">
                    <div className="image" style={{backgroundImage: `url(${imagesIl.length !== 0 ? imagesIl[0].data.images[0] : ""})`}}></div>
                    <div className="content-createBy">
                        <span className="text-createBy">Create by D2P</span>
                    </div>
                </div>
                
                <div className="container-createBrand">
                    <div className="box-titleBrand">
                        <h5 className="text-createBrand">Seu negocio merece um design do seu estilo</h5>
                        <hr />
                    </div>

                    <p className="paragraph-createBrand">
                        Logotipos, sites, design de embalagens e muito mais. Nossa comunidade de designers ajudou diversas empresas a iniciar, <i>crescer</i> , expandir e ir além com designs personalizados e profissionais.
                    </p>

                    <Link to={"/branding"}><span className="text-linkCreateBrand">Inicie sua marca <img src={arrowCurv} alt="->" /></span></Link>

                    <Link to={"/branding"}><button className="button-createBrand">Crie uma marca só sua</button></Link>
                </div>

                <hr className="LinePage" />

                <div className="container-createInspiration">
                    <span className="text-createInspiration">Feitos pela D2P</span>

                    <PhotoAlbum className="container-column" layout="rows" photos={imagesIns} />

                    <Link to="/inspiration"><button className="button-viewMore">Ver mais designs <img src={arrowCurv} alt="" /></button></Link>
                </div>

                <div className="container-showImageIlustration">
                    <div className="image" style={{backgroundImage: `url(${imagesIl.length >= 2 ? imagesIl[1].data.images[1] : ""})`}}></div>
                    <div className="content-createBy">
                        <span className="text-createBy">Create by D2P</span>
                    </div>
                </div>

            </main>

            <Footer/>
        </>
    )
}

export default Index