import React, {useState} from 'react';
import { Link, useNavigate } from "react-router-dom";
import {Header} from "../module/group"
import {logUser} from '../functions/functions'
import googleIcon from "../images/icon/googleIcon.png"
import appleIcon from "../images/icon/appleIcon.png"
import instagramIcon from "../images/icon/instagramIcon.png"
import "../scss/min/login.scss"

function Login(props){
    const [passwordField, setPasswordField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [warning, setWarning] = useState({display: "none", text: ""})
    const navigate = useNavigate();

    async function loginForm(e){
        e.preventDefault()
        if(emailField === "" || passwordField === ""){
            setWarning({display: "block", text: "Preencha todos os campos."})
            return 
        }        
        
        const login = await new logUser().getAuthenticateUser(emailField, passwordField)
        console.log(login)
        if(login.login){
            navigate("/")
        }else{
            if(Response.why === "notUser"){
                setWarning({display: "block", text: "Usuário ou senha incorretos, verifique a ortográfia e tente novamente!"})
            }else if(Response.why === "email"){
                setWarning({display: "block", text: `Seu email não foi validado!, clique em cadastrar e nos informe o codigo enviado para "${emailField}"`})
            }else if(Response.why === "password"){
                setWarning({display: "block", text: `Email ou senha inválidos`})
            }else{
                setWarning({display: "block", text: `Ah não! estamos tendo problema com o login; tente novamente mais tarde!`})
            }
        }      
    }

    function loginAuthenticate(form){
        // new authLogin().serverAuth(form, Respose => {
        //     if(Respose.login){
        //         navigate("/babyPainel")
        //     }else{
        //         if(Respose.why && Respose.why === "notSupported"){
        //             setWarning({display: "block", text: `Ah não! estamos trabalhando para concluir esta implementação.`})
        //         }else{
        //             setWarning({display: "block", text: `Ah não! não conseguimos nos conectar com os servidores de autenticação, tente novamente mais tarde.`})
        //         }
        //     }
        // })
    }

    return (
        <>
            <Header icon={2} color={"#2482C4"}/>
            <main className='main-login'>
                <div className="container-authenticateLogin">
                    <div onClick={(e) => loginAuthenticate(0)} className="box-authenticator" id="login_google"><img src={googleIcon} alt="" /></div>
                    <div onClick={(e) => loginAuthenticate(1)} className="box-authenticator" id="login_apple"><img src={appleIcon} alt="" /></div>
                    <div onClick={(e) => loginAuthenticate(2)} className="box-authenticator" id="login_instagram"><img src={instagramIcon} alt="" /></div>
                </div>

                <div className="container-loginDefault">
                    <div className="container-warning style-warning" style={{display: warning.display}}><span>{warning.text}</span></div>
                    <form onSubmit={loginForm}>
                        <div className="box-interativeUser">
                            <span className='text-inputField'>E-mail</span>
                            <input type="email" id="emailField" className='input-FieldInterative' placeholder='E-mail' value={emailField} onChange={(e) => {setEmailField(e.target.value)}}/>
                        </div>
                        <div className="box-interativeUser">
                            <span className='text-inputField'>Senha</span>
                            <input type="password" minLength={5} autoComplete="on" id="passwordField" className='input-FieldInterative' placeholder='Senha' value={passwordField} onChange={(e) => {setPasswordField(e.target.value)}}/>
                        </div>
                    
                        <div className="box-interativeUser">
                            <button type="submit" className='button-formButton'>Entrar</button>
                            <span className='text-forgoutPassord'>Redefinir senha</span>
                        </div>
                    </form>
                </div>

                <Link to="/registre"><button className='button-alterateTypeUser'>Cadastrar</button></Link>
            </main>
        </>
    )
}

export default Login