import React, { useEffect, useState } from "react";
import {useParams, useNavigate} from 'react-router-dom'
import {INSPIRATION, USER, ADMINPAGE} from '../functions/functions'
import {Header, Loading} from "../module/group"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from "../images/icon/placehoderImage.png"
import "../scss/min/show.inspiration.scss"


function ShowPortifolio(){
    const [viewImage, setViewImage] = useState({open: false, src: ""});
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [images, setImages] = useState([])
    const navigate = useNavigate()
    const { name, crl } = useParams()


    function view(e, open){
        function openBar(e){
            const imageSrc = e.target.src
            setViewImage(prevState => {return {prevState, open: true, src: imageSrc}})
        }

        function closeBar(e){
            setViewImage(prevState => {return {prevState, open: false}})
        }

        if(open){
            openBar(e)
        }else{
            closeBar(e)
        }
    }

    async function createPage(){
        if(name && crl){
            const request = await new INSPIRATION().getEspecificInspiration(crl)
            setImages(request.data.images)
            setLoading(true)
            setData(request)
        }else{
            navigate('/portifolio')
        }
    }

    async function VerifyAdmin(){
        await new USER().isAdm(res => {
            setAdmin(res)
        })
        
    }

    async function remove(){
        setLoading(false)
        const remove = await new ADMINPAGE().remove(data.id, data.data.images, data.data.coverImage, 'inspiration', 'inspiration')
        if(remove[0]){
            if(!remove[1]){
                alert('Arquivos de imagem não foram removidos!')
            }
            navigate('/inspiration')
        }else{
            setAdmin(false)
        }
    }

    useEffect(() => {createPage(); VerifyAdmin()},[])

    return (
        <>

            {loading ? (
                <>
                    <div className={`container-viewImageComponent ${viewImage.open ? "style-open" : "style-close"}`}>
                        <div onClick={(e) => view(e, false)} className="closeMenu"></div>
                        
                        <div className="content-imageView">
                            <LazyLoadImage src={viewImage.src} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                        </div>
                    </div>

                    <main id="main_showInspiration">
                        <Header icon={0} color={"transparent"}/>

                        <div className="container-head">
                            <LazyLoadImage src={data.data.coverImage} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                            <div className="content-createBy">
                                <span className="text-createBy">Create By {data.data.author}</span>
                            </div>
                        </div>

                        <div className="container-imageProject">

                            <div className="container-allImages">

                                {images ? images.map((image, index) => {
                                    if(image.length === 2){
                                        return (
                                            <div className="box-Images" onClick={(e) => view(e, true)}>
                                                {image ? image.map((sub_image, sub_index) => (
                                                    <div className="content-break">
                                                        <LazyLoadImage key={`image-${data.data.nameProject}-${index}-sub-${sub_index}`} src={sub_image} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                                                    </div>
                                                )) : ''}
                                            </div>
                                        )
                                    }else{
                                        return (
                                            <div className="box-Images" onClick={(e) => view(e, true)}>
                                                <LazyLoadImage key={`image-${data.data.nameProject}-${index}-only`} src={image} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                                            </div>
                                        )
                                    }

                                }) : ''}
                            </div>
                        </div>  

                        <div className="container-moreInformation style-showInspiration">
                            <span className="text-titleInformation">Informações da Criação</span>

                            <div className="content-informationIn">
                                <div className="content-informationCon">
                                    <span className="text-informationCon">TIPO:</span>
                                    <button className="button-informationCon"  style={{backgroundColor: "#2482C4"}}>{data.data.information.type}</button>
                                </div>

                                <span className="text-titleInformation style-typeSeparation">BRIEFING <p>(Fictício)</p></span>
                            
                                <p className="paragraph-artigleCreate">
                                    {(data.data.information.briefing)}
                                </p>

                                <div className="content-informationCon">
                                    <span className="text-informationCon">Prazo estimado:</span>
                                    <button className="button-informationCon" style={{backgroundColor: "#316BC5"}}>{data.data.information.timeframe.month} meses e {data.data.information.timeframe.day} dias</button>
                                </div>
                                <div className="content-informationCon">
                                    <span className="text-informationCon">Valor do Projeto: </span>
                                    <button className="button-informationCon" style={{backgroundColor: "#77B72A"}}>R$ {data.data.information.vall}</button>
                                </div>
                            </div>
                        </div>
                        {admin ? (<button onClick={() => remove()} className="button-save style-delete">Excluir Publicação</button>) : ''}
                    </main>

                </>
            ):<Loading/>}

        </>
    )
}

export default ShowPortifolio
