import React from "react";
import { Routes, Route } from "react-router-dom" 

import Index from "../page/index"
import Portifolio from "../page/portifolio";
import ShowPortifolio from "../page/show.portifolio";
import ShowInspiration from "../page/show.inspiration";
import DesignInspiration from "../page/design.inspiration";
import Branding from "../page/branding";
import BrandingNew from "../page/branding.new";
import Login from "../page/login";
import Registre from "../page/registre";
import Account from "../page/myAccount";
import DesignerPage from "../page/design/design_page";
import BudgetView from '../page/budget.view'

import AdminPage from "../page/admin/admin_page";
import CreateInspiration from '../page/admin/create.inspiration'
import CreateProtifolio from '../page/admin/create.portifolio'


import ScrollLoading from "../example/loadingScroll/scroll.loading";

function __Routes(){
    return (
        <Routes>
            <Route exact path="/" element={<Index/>}></Route>
            <Route exact path="/portifolio" element={<Portifolio/>}></Route>
            <Route exact path="/show/portifolio/:name/:crl" element={<ShowPortifolio/>}></Route>
            <Route exact path="/inspiration" element={<DesignInspiration/>}></Route>
            <Route exact path="/show/inspiration/:name/:crl" element={<ShowInspiration/>}></Route>
            <Route exact path="/branding" element={<Branding/>}></Route>
            <Route exact path="/budget/view/:codeID" element={<BudgetView/>}></Route>
            <Route exact path="/create/new/Branding/:codeBranding" element={<BrandingNew/>}></Route>
            <Route exact path="/modify/Branding/:codeBranding" element={<BrandingNew/>}></Route>
            <Route exact path="/login" element={<Login/>}></Route>
            <Route exact path="/registre" element={<Registre/>}></Route>
            <Route exact path="/account" element={<Account/>}></Route>
            <Route exact path="/designer/page" element={<DesignerPage/>}></Route>
            <Route exact path="/admin/page" element={<AdminPage/>}></Route>
            <Route exact path="/admin/create/inspiration" element={<CreateInspiration/>}></Route>
            <Route exact path="admin/create/portifolio" element={<CreateProtifolio/>}></Route>

            {/* examples */}
            <Route exact path="/example/loadingScroll" element={<ScrollLoading/>}></Route>
        </Routes>
    )
}

export default __Routes