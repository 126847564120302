import {logUser} from './log.user'

export class USER{
    async isLog(){
        return await new logUser().getUser() ? true : false;
    }
    async getUIDUser(){
        return await new logUser().getUser();
    }
    async getDataUser(_uid, _id){
        return await new logUser().getDataUser((_uid ? _uid : await this.getUIDUser()), _id)
    }
    async logoutUser(){
        return await new logUser().logOutUser()
    }
    async isAdm(res){
        res(await new logUser().userIsAdm(await this.getUIDUser()))
    }
    async isDesigner(res){
        res(await new logUser().userIsDesigner(await this.getUIDUser()))
    }
    async isLogWithResponse(res){
        res(await new logUser().getUser() ? true : false)
    }
}