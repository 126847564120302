import React, {Component} from "react";
import {Link} from 'react-router-dom'
import "../../scss/module/ContainerUpCase.scss"
import ArrowBack from '../../images/icon/ArrowIcon.svg'
class ContainerUpCase extends Component {
    constructor(props){
        super(props);
    }

    
    SelectContainer = () =>{
        const DefaultContainer = ({fontSize}) => {
            return (
                <div className="container-upCase style-portifolio">
                    <div className="container-tittle">
                        <h1 className="title-page" style={{color: this.props.textColor, fontSize: (fontSize ? fontSize : "")}}>{this.props.text}</h1>
                        <hr style={{backgroundColor: this.props.lineColor}} />
                    </div>
                </div>
            )
        }
    
        const AlterateContainer = ({fontSize}) => {
            return (
                <div className="container-upCase style-portifolio">
                    <div className="container-tittle">
                        <h1 className="title-page" style={{color: this.props.textColor, fontSize: (fontSize ? fontSize : "")}}>{this.props.text}</h1>
                        <button className="hr-writer" style={{backgroundColor: this.props.lineColor}}>
                            {this.props.writer}
                        </button>
                    </div>
                </div>
            )
        }

        const AlterateBackContainer = ({fontSize, link}) => {
            return (
                <div className="container-upCase style-portifolio style-alternativeBack">
                    <Link to={link}>
                        <button className="container-arrow-back">
                            <img src={ArrowBack} alt="" />
                        </button>
                    </Link>
                    <div className="container-tittle">
                        <h1 className="title-page" style={{color: this.props.textColor, fontSize: (fontSize ? fontSize : "")}}>{this.props.text}</h1>
                        <hr style={{backgroundColor: this.props.lineColor}} />
                    </div>
                </div>
            )
        }

        const fontSize = this.props.fontSize ? this.props.fontSize : false
        const link = this.props.link ? this.props.link : '/'
        if(!this.props.type){
            return <DefaultContainer fontSize={fontSize}/>
        }
        if(this.props.type === 2){
            return <AlterateContainer fontSize={fontSize}/>
        }else if (this.props.type === 3){
            return <AlterateBackContainer fontSize={fontSize} link={link}/>
        }else{
            return <DefaultContainer fontSize={fontSize}/>
        }
    }

    render(){
        return (
            <this.SelectContainer/>
        )
    }
}

export default ContainerUpCase