/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {Header, Loading} from "../module/group"
import {ILUSTRATION, USER, ADMINPAGE} from '../functions/functions'
import {useParams, useNavigate} from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from "../images/icon/placehoderImage.png"
import "../scss/min/show.portifolio.scss"


function ShowPortifolio(){
    const [viewImage, setViewImage] = useState({open: false, src: ""});
    const [admin, setAdmin] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const { name, crl } = useParams()
    const navigate = useNavigate()

    function view(e, open){
        function openBar(e){
            const imageSrc = e.target.src
            setViewImage(prevState => {return {prevState, open: true, src: imageSrc}})
        }

        function closeBar(e){
            setViewImage(prevState => {return {prevState, open: false}})
        }

        if(open){
            openBar(e)
        }else{
            closeBar(e)
        }
    }

    async function createPage(){
       if(name && crl){
         const data = await new ILUSTRATION().getEspecificIlustration(crl)
         setData(data)
         setLoading(true)
       }else{
        navigate('/portifolio')
       }
    }

    async function VerifyAdmin(){
        await new USER().isAdm(res => {
            setAdmin(res)
        })
        
    }

    async function remove(){
        setLoading(false)
        const remove = await new ADMINPAGE().remove(data.id, data.data.images, data.data.coverImage, 'portfolio', 'ilustration')
        if(remove[0]){
            if(!remove[1]){
                alert('Arquivos de imagem não foram removidos!')
            }
            navigate('/portifolio')
        }else{
            setAdmin(false)
        }
    }

    useEffect(() => {createPage(); VerifyAdmin();}, [])

    return (
        <>
            {loading  ? (
                <>
                    <div className={`container-viewImageComponent ${viewImage.open ? "style-open" : "style-close"}`}>
                        <div onClick={(e) => view(e, false)} className="closeMenu"></div>
                    
                        <div className="content-imageView">
                            <LazyLoadImage src={viewImage.src} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                        </div>
                    </div>

                    <main id="main_showPortifolio">
                        <Header icon={0} color={"transparent"}/>

                        <div className="container-head">
                            <LazyLoadImage src={data.data.coverImage} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                            <h1 className="text-titleProject">{data.data.information.clientName}</h1>
                        </div>

                        <div className="container-imageProject">
                            <span className="text-titleCompleteName">{data.data.nameProject}</span>
                            <hr />
                            <div className="container-allImages">
                                {data.data.images.map((image, index) => (
                                    <div className="box-Images" onClick={(e) => view(e, true)}>
                                        <LazyLoadImage key={`image-portifolio-${data.data.information.clientName}-${index}`} src={image} width={100} height={100} PlaceholderSrc={PlaceholderImage} />
                                    </div>
                                ))}
                            </div>
                        </div>  

                        <div className="container-moreInformation">
                            <span className="text-titleInformation">Informações</span>

                            <div className="content-informationIn">
                                <span className="text-information"><b>Cliente</b>: {data.data.nameProject}</span>
                                <span className="text-information"><b>Ramo</b>: {data.data.information.type}</span>
                                
                                {data.data.information.website !== '' ? (<div className="button-informationSite">Site: <a href="/">{data.data.information.website}</a> </div>) : ''}
                                <div className="container-socialInformation">
                                    {data.data.information.socialMedia.instagram ? <a href={`www.instagram/${data.data.information.socialMedia.instagram}`}><div className="box-socialInformation"><img src="" alt="" /></div></a> : ''}
                                    {data.data.information.socialMedia.facebook ? <a href={`www.facebook/${data.data.information.socialMedia.facebook}`}><div className="box-socialInformation"><img src="" alt="" /></div></a> : ''}
                                    {data.data.information.socialMedia.linkedin ? <a href={`www.LinkedIn/${data.data.information.socialMedia.linkedin}`}><div className="box-socialInformation"><img src="" alt="" /></div></a> : ''}
                                </div>
                            </div>
                        </div>
                        {admin ? (<button onClick={() => remove()} className="button-save style-delete">Excluir Publicação</button>) : ''}
                    </main>
                </>

            ):<Loading style={{marginTop: '100%'}}/>}

        </>
    )
}

export default ShowPortifolio
