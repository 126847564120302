import React, { useState, useEffect } from 'react'
import exampleJsonBd from "./il.example.json"

function Scroll(props) {
  const [il, setIl] = useState([])
  const [paginationRef, setPaginationRef] = useState([])
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)

  const limitProps = 12 // limit de aparições por "scrolling"
  const ilustrationProps = exampleJsonBd // ilustration json

  function buildPagination(ilustration){
    ilustration = il ? il : ilustration
    var pagination = [[]]
    var selectPagination = 0
    var countForAddPagination = 0

    for (let i = 0; i < ilustration.length; i++) {
        if(countForAddPagination <= limitProps){
          pagination[selectPagination].push(ilustration[i])
          countForAddPagination++
        }else{
          pagination.push([])
          selectPagination++
          countForAddPagination = 0
        }
    }

    setPaginationRef(pagination)
    setItems(pagination[page])
  } 

  useEffect(() => {
    setIl(ilustrationProps)
    buildPagination(ilustrationProps)
  },[])
  
  useEffect(() => {
    fetchData(page)
  }, [page])

  const fetchData = (page) => {
    if(paginationRef[page]){
      const newItems = paginationRef[page]
      setItems([...items, ...newItems])
    }else{
    }

  }

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight

    if (scrollTop + clientHeight + 403 >= scrollHeight) {
      if(page < (paginationRef.length - 1)){
        setPage(page + 1)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [items])

  return (
    <div>
      { items ? items.map((item, index) => (
        <div key={index}>
          {item.name}
          <img src={item.images[0]} alt="" />
        </div>
      )) : ""}
    </div>
  )
}

export default Scroll