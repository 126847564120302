import React, {Component} from "react";
import d2pLineIcon from "../../images/icon/d2pLineIcon.png"
import "../../scss/module/Footer.scss"

import instagran_icon from '../../images/icon/instagramIcon.png'
import linkedin_icon from '../../images/icon/linkedIn_icon.jpg'
import pinterest_icon from '../../images/icon/pinterest_icon.jpg'
import x_icon from '../../images/icon/x_icon.jpg'

class Footer extends Component {
    render(){
        return (
            <>
                <footer>
                    <img className="img-iconFooter" src={d2pLineIcon} alt="" />
                    <hr />
                    <div className="container-informationFooter">
                        <span className="text-Slogan">@ <a target="_blank" rel="noreferrer" href="https://instagram.com/designdeponta_oficial">Design de Ponta</a> | Da ponta, ao ponto</span>
                        
                        <span className="text-terms"><a target="_blank" href="/">Termos de Privacidade</a> | <a href="/">Privacidade</a> | <a href="/"> Mapa do Site</a></span>
                    
                        <div className="container-social">
                            <a target="_blank" href="https://instagram.com/designdeponta_oficial" rel="noreferrer" ><img src={instagran_icon} alt="" className="box-social" /></a>
                            <a target="_blank" href="/"><img src={linkedin_icon} alt="" className="box-social" /></a>
                            <a target="_blank" href="https://br.pinterest.com/DesignDePontaOficial/" rel="noreferrer"><img src={pinterest_icon} alt="" className="box-social" /></a>
                            <a target="_blank" href="https://x.com/DesignPonta" rel="noreferrer"><img src={x_icon} alt="" className="box-social" /></a>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}

export default Footer