import React, {Component} from "react";
import "../../scss/module/ModuleCreateBranding.scss"

import iconBrandingCastle from "../../images/icon/iconBrandingCastle.png"
import iconBrandingCreate from "../../images/icon/iconBrandingCreate.png"
import iconBrandingModify from "../../images/icon/iconBrandingModify.png"
import arrowCurv from "../../images/icon/arrowCurv.svg"
import sendArrowIcon from "../../images/icon/sendArrowIcon.svg"


class InputsQuis extends Component{
    constructor(props){
        super(props);
        this.state = {
            valueinput: "",
            tagValues: [],
            vallInputNameFile: ""
        }
    }

    componentDidUpdate(prevState, prevProps){
        if(prevState.valueinput !== this.state.valueinput){
            if(this.props.type !== 2){
                this.props.sendForFather(this.state.valueinput)
            }
        }
    }

    SelectInput = () => {
        switch(this.props.type){
            case 1: 
                return (<this.InputTypeText/>)
            break;
            case 2: 
                return (<this.InputTypeExtends/>)
            break;
            case 3: 
                return (<this.InputTypeFile/>)
            break;
            default: 
                return (<this.InputTypeTagWithText/>)
        }
    }

    InputTypeText = () => {
        return (
            <>
                <input type="text" className="input-quiz" value={this.state.valueinput} onChange={(e) => this.setState({valueinput: e.target.value})} />
            </>
        )
    }

    InputTypeExtends = () => {
        const sendTagsForFather = (tags) => {
            this.props.sendForFather(tags)
        }

        const AddTag = () => {
            const textForTag = this.state.valueinput
            const oldTags = this.state.tagValues
            oldTags.push(textForTag)
            this.setState({tagValues: oldTags, valueinput: ""})
            sendTagsForFather(oldTags)
        }

        const deleteTag = index => {
            const oldTags = this.state.tagValues
            oldTags[index] = null
            this.setState({tagValues: oldTags.filter(key => key)})
            sendTagsForFather(oldTags.filter(key => key))
        }

        const handleKeyPress = (event) => {
            if(event.key === 'Enter'){
                AddTag()
            }
        }

        return (
            <>
                <div className="content-inputExtends">
                    <input type="text" id="input-tag-extends" className="input-quiz style-inputExtends" placeholder="Tags" value={this.state.valueinput} onKeyPress={handleKeyPress} onChange={(e) => this.setState({valueinput: e.target.value})} />
                    <button onClick={(e) => AddTag()} className="button-send"><img src={sendArrowIcon} alt="" /></button>
                    <div className="box-tagsExtends">
                        {this.state.tagValues[0] ? this.state.tagValues.map((value, index) => (
                            <button key={index} className="content-tag">{value} <button onClick={(e) => deleteTag(index)} className="button-deleteTag">X</button></button>
                        )) : ""}
                    </div>
                </div>
            </>
        )
    }

    InputTypeFile = () => {
        return (
            <>
                <label htmlFor="input-file" className="label-InputFiles">
                    <div className="button-getFiles">Fazer Upload</div>
                    <input id="input-file" type="file" accept="image/*" value={this.state.vallInputNameFile} onChange={(e) => {this.setState({valueinput: e.target.files, vallInputNameFile: e.target.value})}} style={{display: "none"}} multiple={false}/>
                </label>
            </>
        )
    }

    InputTypeTagWithText = () => {
        return (
            <>
                <div className="container-inputTag">
                    <select className="select-inputTag">
                        {this.props.select.map((value, index) => (
                            <option value={value}>{value}</option>
                        ))}
                    </select>
                    <input type="text" className="input-quiz style-inputTag" value={this.state.valueinput} onChange={(e) => this.setState({valueinput: e.target.value})}  />
                </div>
            </>
        )
    }
    
    render(){
        return (
            <>
                <div className="container-inputs">  
                    <div className="content-text">
                        <span className="text-input">{this.props.title}</span>
                        {(this.props.required) ? (<span className="if-require">*</span>) : ""}
                    </div>
                    <this.SelectInput/>
                </div>
            </>
        )
    }
}

class OptionsMark extends Component{
    constructor(props){
        super(props)
        this.state = {
            mark: []
        }
    }

    componentDidMount(){
        const setMark = () => {
            const mark = this.props.mark.map(index => {
                return false
            })
            this.setState({mark: mark})
        }

        setMark()
    }

    componentDidUpdate(prevState, prevProps){
        if(prevState.mark !== this.state.mark){
            this.props.sendForFatherOptions(this.state.mark)
        }
    }

    selectThisOption = index => {
        let options = this.state.mark
        options[index] = !options[index]
        this.setState({mark: options})
    }

    render(){
        return (
            <>
                <div className="container-OptionsMark">
                    {this.props.mark.map((option, key) => (
                        <button key={`optionMark-${key}`} onClick={(e) => this.selectThisOption(key)} className={`optionsMark ${this.state.mark[key] ? "style-selectOptionMark" : ""}`}>{option}</button>
                    ))}
                </div>
            </>
        )
    }
}

class UpBarQuis extends Component{
    constructor(props){
        super(props);
    }

    ImgSelect = () => {
        if(this.props.type === 1){
            return ( <img src={iconBrandingCreate} alt="" /> )
        }else if(this.props.type === 2){
            return ( <img src={iconBrandingCastle} alt="" /> )
        }else{
            return ( <img src={iconBrandingModify} alt="" /> )
        }
    }

    render(){
        return (
            <>
                <div id="UpBarQuiz">
                    <div className="container-UpCase">
                        <div className="content-text">
                            <span className="text-UpCase">{this.props.title}</span>
                            <hr />
                        </div>
                        <this.ImgSelect/>
                    </div>    

                    
                    <h1 className="text-titlePage" style={{color: this.props.color}}>{this.props.textPage} {this.props.IAIcon ? (<span className="IATAG">IA</span>) : ''}</h1>
                    <hr className="hr-titlePage" style={{backgroundColor: this.props.color}} />
                </div>   
            </>
        )
    }
}

class DownBarQuis extends Component{
    constructor(props){
        super(props);
    }

    
    Sendnext = () => {
        this.props.buttonClicked(1, this.props.page -1)
    }
    
    Sendback = (back) => {
        this.props.buttonClicked(0, back)
    }

    render(){
        return (
            <>
                <div id="DownBarQuiz">
                    <div className="container-control">
                        <img src={arrowCurv} alt="" onClick={(e) => this.Sendback(this.props.back)} />
                        <div className="box-controlNumber">
                            <span className="text-controlNumber style-nowNumber">{this.props.page}</span>
                            <span className="text-controlNumber">/5</span>
                        </div>
                    </div>
                    <button onClick={(e) => this.Sendnext()} className="button-next">Continuar</button>
                </div>
            </>
        )
    }
}

class BrandingResumeIA extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return (
            <div id="branding-resume-ia">
                <p className="paragraph-resume-ia">
                    {this.props.resume.split(/\n/).map((paragraph) =>{
                        if(paragraph !== ""){
                            return paragraph 
                        }else{
                            return (<><br/><br/></>)
                        }
                    })}
                </p>
            </div>
        )
    }
}

export {InputsQuis, UpBarQuis, DownBarQuis, OptionsMark, BrandingResumeIA}