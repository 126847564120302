import {firestore} from './QUERY'
import {getImageOfTheData} from '../module/processing.data'

export class ILUSTRATION{
    async getAllIlustration(){
        const ilustration = await new firestore().get({bd: 'portfolio'})
        return await getImageOfTheData(ilustration, 'ilustrations', true)
    }

    async getEspecificIlustration(id){
        const ilustration = await new firestore().getWhere({bd: 'portfolio', where: ['documentID', id]})
        return await getImageOfTheData(ilustration[0], 'ilustrations', false)
    }
}

export class INSPIRATION{
    async getAllInspiration(){
        const ilustration = await new firestore().get({bd: 'inspiration'})
        return await getImageOfTheData(ilustration, 'inspiration', true)
    }

    async getEspecificInspiration(id){
        const ilustration = await new firestore().getWhere({bd: 'inspiration', where: ['documentID', id]})
        return await getImageOfTheData(ilustration[0], 'inspiration', false)
    }  
}