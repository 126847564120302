import React, {useEffect, useState} from "react";
import { useNavigate, Link } from "react-router-dom";
import PhotoAlbum from "react-photo-album";
import {Header, ContainerUpCase, Loading} from "../../module/group"
import {USER, ILUSTRATION, INSPIRATION} from '../../functions/functions'
import {sizeType} from "../../functions/mainFunction"
import arrowCurv from "../../images/icon/arrowCurv.svg"
import "../../scss/min/myAccount.scss"
import "../../scss/min/index.scss"


function Admin(){
    const [ilustration, setIlustration] = useState([])
    const [portifolio, setPortifolio] = useState([])
    const navigate = useNavigate();

    async function constructData(){
        const request_ilustration = await new ILUSTRATION().getAllIlustration()
        const request_inspiration = await new INSPIRATION().getAllInspiration()
        setIlustration(selectOnlyImages(request_ilustration))
        setPortifolio(selectOnlyImages(request_inspiration))

        function selectOnlyImages(arr_data){
            const photos = []
            for (let i = 0; i < arr_data.length; i++) {
                const data = arr_data[i];
                photos.push({
                    src: data.data.images[0],
                    width: 1600,
                    height: 900
                })
            }
            return photos
        }
    }

    useEffect(() => {
        sizeType()
        new USER().isAdm(res => res ? '' : navigate('/login'))
        constructData()
    },[])

    return (
        <>
            <Header icon={2} color={"#4F3CC7"}/>

            <main id="myAccount_main">
                <ContainerUpCase fontSize={"2.3em"} textColor={"#810296"} lineColor={"#810296"} text={"Admin Page"} />                


                <div className="container-mainRequest container-createInspiration" id="style-text-inspiration">
                    {ilustration.length !== 0 ? (<PhotoAlbum className="container-column" layout="rows" photos={ilustration} /> ) : (<Loading style={{marginTop: '0%'}}/>)}
                    <Link to="/admin/create/inspiration"><button className="button-viewMore">Criar Inspiração <img src={arrowCurv} alt="" /></button></Link>
                </div>

                <div className="container-acceptedRequest container-createInspiration" id="style-text-portifolio">
                    {portifolio.length !== 0 ? (<PhotoAlbum className="container-column" layout="rows" photos={portifolio} /> ) : (<Loading style={{marginTop: '0%'}}/>)}
                    <Link to="/admin/create/portifolio"><button className="button-viewMore">Criar Portifólio <img src={arrowCurv} alt="" /></button></Link>
                </div>
            </main>
        </>
    )
}

export default Admin