import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Header, ContainerUpCase} from "../../module/group"
import {USER, DESIGNPAGE} from '../../functions/functions'
import {sizeType} from "../../functions/mainFunction"
import iconBrandingCastle from "../../images/icon/iconBrandingCastle.png"
import iconBrandingCreate from "../../images/icon/iconBrandingCreate.png"
import iconBrandingModify from "../../images/icon/iconBrandingModify.png"
import "../../scss/min/myAccount.scss"


function BoxMainRequest({typeRequest, nameRequest, statusRequest, informationRequest, onClick}){
    const colorStatus = ["#E4C312", "#3e75b4", "#77B72A", "#B20C0C"]
    function GetImageRequest({typeRequest}){
        function GetImage(){
            switch(parseInt(typeRequest)){
                case 1: 
                    return <img src={iconBrandingCreate} alt="create new" />
                break;
                case 2: 
                    return <img src={iconBrandingModify} alt="modify logo" />
                break;
                default:
                    return <img src={iconBrandingCastle} alt="create new logo" />
            }
        }
    
        return (
            <GetImage/>
        )
    }
    
    function ContentInformationUserRequest({textProps, textValue, style}){
        if(!style){
            style = undefined;
        }
        return(
            <>
                <div className="content-informationProps">
                    <span className="text-informationProps">{textProps}</span>
                    <span className="content-informationValue" style={style}>{textValue}</span>
                </div>
            </>
        )
    }

    function getNameStatusRequest(statusRequest){
        switch(statusRequest){
            case 0:
                return "Em analize"
            break;
            case 1:
                return "Criando"
            break;
            case 2:
                return "Concluído"
            break;
            default:
                return "Finalizado"
        }
    }

    return (
        <>
            <div className="box-mainRequest">
                <div className="content-informationRequest">
                    <GetImageRequest typeRequest={typeRequest}/>
                    <div className="content-nameAndStatus">
                        <span className="text-nameRequest">{nameRequest}</span>
                        <span className="text-statusRequest">status: <i style={{color: colorStatus[statusRequest]}}>{getNameStatusRequest(statusRequest)}</i></span>
                    </div>
                </div>

                <div className="contentInformationUserRequest">
                    <ContentInformationUserRequest style={{textDecoration: "underline"}} textProps={"Contato:"} textValue={informationRequest.email}/>
                    <ContentInformationUserRequest style={{textDecoration: "underline"}} textProps={"Nome da empresa/projeto: "} textValue={informationRequest.projectName}/>
                </div>

                {statusRequest === 1 ? (
                    <button onClick={onClick} title="Visualizar Orçamento" className="button-downloadRequest">Visualizar</button>
                ):""}
            </div>
        </>
    )
}

function Portifolio(){
    const [budgetsForCreate, setBudgetsForCreate] = useState([])
    const [budgetsDesigner, setBudgetsDesignder] = useState([])
    const statusText = ['Requisição de orçamento', 'Em analize', "Criando"]
    const navigate = useNavigate();

    function openRequest(idRequest){
        navigate(`/budget/view/${idRequest}`)
    }

    async function constructData(){
        const requestBudgetsForCreate = await new DESIGNPAGE().requestAllBudgets()
        const requestMyBudgets = await new DESIGNPAGE().requestMyBudgets()

        if(requestBudgetsForCreate[0] && requestMyBudgets[0]){
            setBudgetsForCreate(requestBudgetsForCreate[1])
            setBudgetsDesignder(requestMyBudgets[1])
        }
    }

    useEffect(() => {
        sizeType()
        new USER().isDesigner(res => res ? '' : navigate('/login'))
        constructData()
    },[])

    return (
        <>
            <Header icon={2} color={"#262172"}/>

            <main id="myAccount_main">
                <ContainerUpCase fontSize={"2.3em"} textColor={"#EC6707"} lineColor={"#EC6707"} text={"Designer Page"} />                


                <div className="container-mainRequest">
                    {budgetsForCreate && budgetsForCreate.length > 0 ? (
                        budgetsForCreate?.map((budget, index) => (
                            <BoxMainRequest key={`budgets_create_${index}_${budget?.data?.nameProject}`} onClick={(e) => openRequest(budget?.id)}typeRequest={budget?.data?.typeBanding} nameRequest={statusText[budget?.data?.status]} statusRequest={1} informationRequest={{email: budget?.data?.userCreate?.data?.tell, projectName: budget?.data?.nameProject}}/>
                        ))
                    ):'Nenhum budgets para visualizar'}
                </div>

                <div className="container-acceptedRequest" id="admin_page_style">
                    {budgetsDesigner && budgetsDesigner.length > 0 ? (
                        budgetsDesigner?.map((budget, index) => (
                            <>
                                <BoxMainRequest key={`budgets_my_${index}_${budget?.data?.nameProject}`} onClick={(e) => openRequest(budget?.id)}typeRequest={budget?.data?.typeBanding} nameRequest={statusText[budget?.data?.status]} statusRequest={1} informationRequest={{email: budget?.data?.userCreate?.data?.tell, projectName: budget?.data?.nameProject}}/>
                            </>
                        ))
                    ):'Nenhum projeto em andamento'}
                </div>
            </main>
        </>
    )
}

export default Portifolio