import React, {Component} from "react";
import {ScrollLoading, Loading} from "../group"
import {ILUSTRATION} from '../../functions/functions'
import "../../scss/module/ShowPortifolio.scss"

class ShowPortifolio extends Component {
    constructor(props){
        super(props)
        this.state = {
            ilustration: []
        }
    }

    async componentDidMount(){
        const response = await new ILUSTRATION().getAllIlustration()
        this.setState({ilustration:response})
    }

    render(){
        return (
            <>
                <div className="container-portifolio">
                    {this.state.ilustration[0] ? <ScrollLoading limit="12" itens={this.state.ilustration}/> : <Loading style={{marginTop: '0%'}}/>}
                </div>
            </>
        )
    }
}

export default ShowPortifolio