import react from 'react'
import "../../scss/module/Loading.scss"

export default function Loading(style){
    return (
        <div className="loading" style={style}>
            <span className='text-loading'>Gerando</span>
            <div className="container_balls_animate">
                <div id="ball1"></div>
                <div id="ball2"></div>
                <div id="ball3"></div>
            </div>
        </div>
    )
}